import React from "react";
import { Modal, Card, CardContent, CardHeader } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Router, Route, Switch } from "react-router-dom";

import { mainRouterHistory } from "./routerHistories";
import { useAuth, authContext } from "./contexts/auth";
import { appContext, useApp } from "./contexts/app";
import { theme } from "./theme/theme";

// import NavBar from "./components/CustomNavBar";
import Dashboard from "./components/pages/Dashboard";
import Office from "./components/pages/OfficeDetail";

import Login from "./components/pages/Login";
import ForgotPassword from "./components/pages/ForgotPassword";
import Register from "./components/pages/Register";
import TermsOfService from "./components/pages/TermsOfService";

function App() {
    const appState = useApp();

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <appContext.Provider value={appState}>
                    <authContext.Provider value={useAuth()}>
                        {/* <NavBar /> */}
                        <Router history={mainRouterHistory}>
                            <Switch>
                                <Route exact path="/" component={Login} />
                                <Route path="/forgot-password" component={ForgotPassword} />
                                <Route path="/terms-of-service" component={TermsOfService} />
                                <Route path="/sign-up" component={Register} />
                                <Route path="/dashboard" component={Dashboard} />
                                <Route path="/office/:id" component={Office} />
                            </Switch>
                        </Router>

                    </authContext.Provider>
                    <Modal
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex"
                        }}
                        open={
                            appState.error.message &&
                                appState.error.message.length > 0
                                ? true
                                : false
                        }
                        onClose={() => {
                            appState.error.setMessage(null);
                        }}
                    >
                        <Card style={{ margin: "auto" }}>
                            <CardHeader title="Error" />
                            <CardContent>{appState.error.message}</CardContent>
                        </Card>
                    </Modal>
                </appContext.Provider>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App
