import React, { useState, useContext, useEffect } from 'react';

import { makeStyles, Tabs, Tab, Box } from '@material-ui/core';

import { usePaging, PagingContext } from "./PagingContext";
import { OfficeContext } from "../OfficeContext";
import ErrorStateDisplay from "../../../ErrorStateDisplay/ErrorStateDisplay";

import Rooms from './Rooms';
import MeaningButtons from './MeaningButtons';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}


const useStyles = makeStyles({
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
        fontFamily: 'SF Pro Display',
    },
    customTabs: {
        '& .MuiTab-root': {
            textTransform: 'none',
        },
        '& .MuiTabs-indicator': {
            height: 0,
            backgroundColor: 'transparent',
        }
    },
    customTab: {
        minWidth: 'fit-content',
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 25,
        minHeight: 26,
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: 'SF Pro Display',
    }
});

function Paging() {
    const classes = useStyles();
    const { officeId } = useContext(OfficeContext);
    const paging = usePaging(officeId);
    const [value, setValue] = useState(0);

    useEffect(() => {
        paging.getPaging();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (paging.error) {
        return (
            <ErrorStateDisplay
                title="Error Getting Paging"
                message={paging.error}
                retry={paging.getPaging}
            />
        );
    }

    return (
        <PagingContext.Provider value={paging}>
            <div style={{ marginTop: 30 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        className={classes.customTabs}
                    >
                        <Tab
                            value={0}
                            label="Rooms"
                            className={classes.customTab}
                            style={{
                                background: value === 0 ? '#197FE8' : '#F8F9FB',
                                color: value === 0 ? 'white' : '#979797'
                            }}
                        />
                        <Tab
                            value={1}
                            label="Meaning buttons"
                            className={classes.customTab}
                            style={{
                                background: value === 1 ? '#197FE8' : '#F8F9FB',
                                color: value === 1 ? 'white' : '#979797'
                            }}
                        />
                    </Tabs>
                </div>
                <TabPanel value={value} index={0}>
                    <Rooms />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MeaningButtons />
                </TabPanel>
            </div>
        </PagingContext.Provider>
    );
}

export default Paging;
