import React, { useState, useContext, forwardRef } from "react";
import {
    Card,
    CardContent,
    CardActions,
    Divider,
    CircularProgress,
    makeStyles,
} from "@material-ui/core";
import { BillingContext } from '../BillingContext'
import { OfficeContext } from "../../OfficeContext";
import { appContext } from "../../../../../contexts/app";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";

import CustomTextField from "../../../../CustomTextField";
import CustomButton from "../../../../CustomButton";

const useStyles = makeStyles({
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
        fontFamily: 'SF Pro Display'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        fontFamily: 'SF Pro Display'
    },
});

const ChangeEmail = forwardRef((props, ref) => {
    const { emailUser, handleClose } = props;
    const classes = useStyles();

    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const { customer } = useContext(BillingContext);

    const [email, setEmail] = useState(emailUser);
    const [loading, setLoading] = useState(false);
    const [errorField, setErrorField] = useState(false);
    const [emailValidate, setEmailValidate] = useState(false);

    const onChangeEmail = (event) => {
        const caracteres = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const t = event.currentTarget.value.split(" ");
        let text = "";
        t.map((r) => {
            if (r !== "") {
                text += r;
            }
        });
        setEmail(text.toLowerCase());
        if (text !== "") {
            if (caracteres.test(text)) {
                setEmailValidate(false);
            } else {
                setEmailValidate(true);
            }
        } else {
            setEmailValidate(true);
        }
    };

    const subminEmail = () => {
        setLoading(true);
        if (email === '' || emailValidate === true) {
            setErrorField(true)
            setLoading(false);
        } else {
            insyncHttps.billing
                .changeCustomerEmail({ officeId, email })
                .then(result => {
                    setLoading(false);
                    handleClose();
                    customer.getCustomer();
                })
                .catch(err => {
                    setLoading(false);
                    error.setMessage(err.message);
                });
        }
    }

    return (
        <Card ref={ref} className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 20 }} className={classes.title}>
                        Change Billing Email
                    </p>
                    <p
                        style={{ marginBottom: 20, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }}/>
                <div style={{ marginBottom: 46, paddingLeft: 25, paddingRight: 25, fontFamily: 'SF Pro Display' }}>
                    <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 30 }}>
                        Change email
                    </p>
                    <CustomTextField
                        value={email}
                        placeholder="your@email.com"
                        error={email !== '' ? emailValidate : errorField}
                        onChange={onChangeEmail}
                        helperText={email && emailValidate ? "Please enter a valid email" : ''}
                    />
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }}/>
            </CardContent>
            <CardActions style={{ marginTop: 32, paddingLeft: 25, paddingRight: 25 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        width='full'
                        label='submit'
                        onClick={subminEmail}
                    />
                }
            </CardActions>
        </Card>
    );
});
export default ChangeEmail