import React, { useState, useContext } from "react";
import {
    useMediaQuery,
    IconButton,
    CircularProgress,
    makeStyles,
    Dialog
} from "@material-ui/core";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';

import { BillingContext } from "../BillingContext";
import AddPaymentSource from "./AddPaymentSource";
import ErrorStateDisplay from "../../../../ErrorStateDisplay/ErrorStateDisplay";
import CustomButton from "../../../../CustomButton";
import PaymentSourceDetails from './PaymentSourceDetails';

const useStyles = makeStyles({
    infoContainer: {
        borderRadius: 4,
        paddingTop: 34,
        paddingBottom: 34,
        paddingLeft: 30,
        paddingRight: 26,
        background: 'white',
        border: '1px solid #EDEEF0',
        marginTop: 30,
        userSelect: 'none'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
        fontFamily: 'SF Pro Display'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        fontFamily: 'SF Pro Display'
    },
});

export default props => {
    const classes = useStyles();
    const smDown = useMediaQuery('(max-width:600px)');
    const { paymentSources } = useContext(BillingContext);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [viewedSource, setViewedSource] = useState(false);
    const [dataCard, setDataCard] = useState();

    let content;
    if (paymentSources.error) {
        content = (
            <ErrorStateDisplay
                title="Error Getting Payment Sources"
                message={paymentSources.error}
                retry={paymentSources.getSources}
            />
        );
    } else {
        content = (
            <React.Fragment>
                {paymentSources.loading ? (
                    <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                ) : (
                    <div>
                        {paymentSources.sources.length > 0 ?
                            <div >
                                {paymentSources.sources.map(paymentSourceItem => {
                                    const paymentSource = paymentSourceItem.payment_source;
                                    return (
                                        <div
                                            key={paymentSource.id}
                                            style={{
                                                marginTop: 10,
                                                paddingTop: 20,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                paddingBottom: 10,
                                                borderTop: '1px solid #EDEEF0'
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {paymentSource.card.brand === 'visa' ?
                                                    <img src='/assets/cardVisa.png' /> :
                                                    paymentSource.card.brand === 'mastercard' ?
                                                        <img src='/assets/cardMastercard.png' /> :
                                                        paymentSource.card.brand === 'american_express' ?
                                                            <img src='/assets/cardAmericanExpress.png' /> :
                                                            paymentSource.card.brand === 'discover' ?
                                                                <img src='/assets/cardDiscover.png' /> :
                                                                paymentSource.card.brand === 'jcb' ?
                                                                    <img src='/assets/cardJcb.png' /> :
                                                                    <CreditCardRoundedIcon style={{ color: '#979797' }} />
                                                }
                                                <div style={{ marginLeft: 15 }}>
                                                    <p style={{ margin: 0, fontFamily: 'SF Pro Display' }}>
                                                        {paymentSource.card.masked_number}
                                                    </p>
                                                    <p className={classes.subTitle}>
                                                        {`${paymentSource.card.expiry_month}/${paymentSource.card.expiry_year}`}
                                                    </p>
                                                </div>
                                            </div>
                                            <IconButton
                                                style={{ height: 40, width: 40 }}
                                                onClick={() => {
                                                    setViewedSource(true);
                                                    setDataCard(paymentSource)
                                                }}
                                            >
                                                <CreateRoundedIcon style={{ color: '#9DA2B4' }} />
                                            </IconButton>
                                        </div>
                                    );
                                })}

                            </div>
                            :
                            <div
                                style={{
                                    marginTop: 20,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexDirection: smDown ? 'column' : 'row',
                                    borderTop: '1px solid #EDEEF0'
                                }}
                            >
                                <p
                                    style={{
                                        color: '#515151',
                                        fontSize: 20,
                                        width: smDown ? '90%' : '40%',
                                        fontFamily: 'SF Pro Display'
                                    }}
                                >
                                    To enjoy the platform you need to add
                                    a means of payment
                                </p>
                                <div style={{ width: smDown ? '90%' : '40%' }}>
                                    <CustomButton
                                        width='full'
                                        label='ADD a credit Card'
                                        onClick={() => { setAddDialogOpen(true) }}
                                    />
                                </div>
                            </div>
                        }
                        <Dialog open={addDialogOpen}>
                            <AddPaymentSource
                                handleClose={() => {
                                    setAddDialogOpen(false);
                                }}
                            />
                        </Dialog>
                        {viewedSource ?
                            <Dialog open={viewedSource}>
                                <PaymentSourceDetails
                                    dataCard={dataCard}
                                    handleClose={() => {
                                        setViewedSource(false);
                                    }}
                                />
                            </Dialog>
                            : ''
                        }
                    </div>
                )
                }
            </React.Fragment >
        );
    }

    return (
        <div className={classes.infoContainer}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p className={classes.title}>
                    Payment sources
                </p>
                <div>
                    <IconButton
                        onClick={() => { setAddDialogOpen(true) }}
                        style={{ background: 'rgba(157, 162, 180, 0.08)', padding: 5, height: 40, width: 40 }}
                    >
                        <AddRoundedIcon style={{ color: '#9DA2B4' }} fontSize='medium' />
                    </IconButton>
                    {paymentSources.sources.length > 0 ?
                        <IconButton
                            onClick={() => { paymentSources.getSources() }}
                            style={{ padding: 5, height: 40, width: 40 }}
                        >
                            <AutorenewRoundedIcon style={{ color: '#9DA2B4' }} fontSize='medium' />
                        </IconButton>
                        : ''
                    }
                </div>
            </div>
            {content}
        </div>
    );
};
