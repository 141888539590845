import firebase from "../../firebase";

export default function({  officeId, email, roleName }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "officeAdmin",
        funcName: "addOfficeRoleToUser",
        data: {
            
            officeId,
            email,
            roleName
        }
    });
}
