import React, { useContext, useState, useEffect } from 'react';
import {
    IconButton,
    CircularProgress,
    makeStyles,
    Modal,
    Dialog,
} from "@material-ui/core";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import { OfficeContext } from "../OfficeContext";
import { UsersContext, useUsers } from "./UsersContext";

import AddUser from './AddUser';
import EditDataUser from './EditDataUser';

const useStyles = makeStyles({
    infoContainer: {
        borderRadius: 4,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 26,
        background: 'white',
        border: '1px solid #EDEEF0',
        marginTop: 30,
        fontFamily: 'SF Pro Display'
    },
    containerInfoUser: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: '1px solid #EDEEF0',
        height: 64,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    customDialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        '& .MuiPaper-elevation24': {
            boxShadow: 'none'
        }
    }
});

function Roles() {
    const classes = useStyles();
    const { officeId } = useContext(OfficeContext);
    const users = useUsers(officeId);
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [editDatauserOpen, setEditDatauserOpen] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(null);

    useEffect(() => {
        users.getUsers();
    }, []);

    return (
        <UsersContext.Provider value={users}>

            <div className={classes.infoContainer}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 16
                }}>
                    <p className={classes.title}>
                        Accounts
                    </p>
                    <IconButton
                        style={{ background: 'rgba(157, 162, 180, 0.08)', padding: 5 }}
                        onClick={() => {
                            setAddUserOpen(true);
                        }}
                    >
                        <AddRoundedIcon style={{ color: '#9DA2B4' }} fontSize='medium' />
                    </IconButton>
                    <Modal
                        style={{ display: "flex" }}
                        open={addUserOpen}

                    >
                        <AddUser
                            handleClose={() => {
                                setAddUserOpen(false);
                            }}
                        />
                    </Modal>
                </div>
                {users.loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 15 }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                ) : (
                    users.users.map(user => {
                        return (
                            <div className={classes.containerInfoUser} key={user.uid}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    {/* <p style={{ marginRight: 25 }}> nom,bre</p>
                                    <div style={{ height: 33, width: 1, background: '#EDEEF0' }} /> */}
                                    <p style={{ marginRight: 25, width: 350 }}>
                                        {user.email}
                                    </p>
                                    <div style={{ height: 33, width: 1, background: '#EDEEF0' }} />
                                    <div style={{ marginLeft: 25 }}>
                                        {user.roles.map(rol => (
                                            <span style={{ marginLeft: 5 }} key={rol}>
                                                {rol === 'billingAdmin' ?
                                                    'Billing admin -' : rol === 'officeAdmin' ?
                                                        'Office admin -' : 'Office user -'
                                                }
                                            </span>
                                        ))}

                                    </div>
                                </div>
                                <div>
                                    <IconButton
                                        onClick={() => {
                                            setEditDatauserOpen(true);
                                            setDataToEdit({ ...users[user], user })
                                            // setDataToEdit(user.uid)
                                        }}
                                        style={{ padding: 5, height: 40, width: 40 }}
                                    >
                                        <EditRoundedIcon style={{ color: '#9DA2B4' }} />
                                    </IconButton>

                                </div>
                                <Dialog
                                    style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}
                                    open={editDatauserOpen}
                                    className={classes.customDialog}
                                >
                                    <EditDataUser
                                        user={dataToEdit}
                                        handleClose={() => {
                                            setEditDatauserOpen(false);
                                        }}
                                    />
                                </Dialog>
                            </div>
                        );
                    })
                )}

            </div>
        </UsersContext.Provider>
    );
}

export default Roles;
