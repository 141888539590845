import React from "react";
import {
    CardContent,
    Card,
    Grid
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CustomButton from "../CustomButton";

export default function (props) {
    const title = props.title;
    const message = props.message;
    const retry = props.retry;
    return (
        <Card>
            <CardContent>
                <Grid container align="center" justify="center">
                    <Grid xs={11} sm={10} lg={7} xl={6}>
                        {title}
                    </Grid>
                    <Grid xs={11} sm={10} lg={7} xl={6}>
                        <ErrorOutlineIcon />
                    </Grid>
                    <Grid xs={11} sm={10} lg={7} xl={6}>
                        {message}
                    </Grid>
                    <Grid xs={11} sm={10} lg={7} xl={6}>
                        <CustomButton
                            backgroundred='#F86F46'
                            onClick={retry}
                            label='retry'
                            width='full'
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
