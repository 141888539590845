import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    headInformation: {
        height: 37,
        display: 'flex',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    },
    typography: {
        fontFamily: 'SF Pro Display',
        color: '#979797',
        fontSize: 13,
        fontWeight: 500,
    },
    divContainer: {
        height: 33,
        paddingLeft: 10,
        paddingRight: 10,
        borderRight: '1px solid #EDEEF0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

function HeaderInformation() {
    const classes = useStyles();

    return (
        <div className={classes.headInformation}>
            <div
                style={{ width: '20%' }}
                className={classes.divContainer}
            >
                <p className={classes.typography}>Full name</p>
            </div>

            <div style={{ width: '15%' }} className={classes.divContainer}>
                <p className={classes.typography}>Nickname</p>
            </div>

            <div
                className={classes.divContainer}
                style={{ justifyContent: 'center', width: '6%' }}
            >
                <p className={classes.typography}>Sound</p>
            </div>

            <div className={classes.divContainer} style={{ width: '15%' }}>
                <p className={classes.typography}>Phone</p>
            </div>

            <div
                className={classes.divContainer}
                style={{ justifyContent: 'center', width: '6%', minWidth: 50  }}
            >
                <p className={classes.typography}>Paging</p>
            </div>

            <div
                className={classes.divContainer}
                style={{ justifyContent: 'center', width: '6%', minWidth: 50  }}
            >
                <p className={classes.typography}>Messaging</p>
            </div>

            <div
                className={classes.divContainer}
                style={{ justifyContent: 'center', width: '6%', minWidth: 60  }}
            >
                <p className={classes.typography}>Password</p>
            </div>

            <div
                className={classes.divContainer}
                style={{ justifyContent: 'center', width: '6%', minWidth: 70  }}
            >
                <p className={classes.typography}>Order</p>
            </div>

            <div
                className={classes.divContainer}
                style={{ borderRight: 'none', justifyContent: 'center', width: '6%', minWidth: 70  }}
            >
                <p className={classes.typography}>Actions</p>
            </div>
        </div>
    );
}

export default HeaderInformation;
