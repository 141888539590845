import React, { useContext, useEffect, useState } from 'react';

import { Divider, makeStyles, CircularProgress } from '@material-ui/core';

import { PatientTrackingContext } from "../PatientTrackingContext";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";
import { OfficeContext } from "../../OfficeContext";
import { appContext } from "../../../../../contexts/app";

import CustomRadioCheck from '../../../../CustomRadioCheck';

const useStyles = makeStyles({
    infoContainer: {
        background: 'white',
        borderRadius: 4,
        marginTop: 21,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'SF Pro Display',
        border: '1px solid #EDEEF0',
        padding: 30,
        height: 'fit-content'
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
    },
    subtitle: {
        fontSize: 16,
        margin: 0,
        wordBreak: 'break-all',
        marginBottom: 7,
        marginTop: 21
    },
});

function AdjustAppointment() {
    const classes = useStyles();
    const { getPatientTracking, loading, visitLate } = useContext(PatientTrackingContext);
    const { officeId } = useContext(OfficeContext);

    const [lateArrivals, setLateArrivals] = useState(`${visitLate.lateArrivals}`);
    const { error } = useContext(appContext);

    useEffect(() => {
        setLateArrivals(`${visitLate.lateArrivals}`);
    }, [visitLate]);

    const updateVisitSetting = (lateArrivals) => () => {
        insyncHttps.patientTracking
            .updateVisitLate({
                officeId,
                lateArrivals
            })
            .catch(e => {
                error.setMessage(e);
            })
            .then(e => {
                getPatientTracking();
            });
    }


    return (
        <div className={classes.infoContainer}>
            <p className={classes.title}>
                Adjust Appointment
            </p>
            <Divider style={{ backgroundColor: '#EDEEF0', marginTop: 15 }} />
            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                <div>
                    <p className={classes.subtitle}>
                        Late Arrivals
                    </p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomRadioCheck
                            onChange={updateVisitSetting('yes')}
                            checked={lateArrivals === 'yes'}
                        />
                        <p style={{ color: lateArrivals === 'yes' ? '#197FE8' : 'black' }}>
                            Yes
                        </p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomRadioCheck
                            onChange={updateVisitSetting('no')}
                            checked={lateArrivals === 'no'}
                        />
                        <p style={{ color: lateArrivals === 'no' ? '#197FE8' : 'black' }}>
                            No
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}

export default AdjustAppointment;
