import React, { useState, useContext } from 'react';

import { makeStyles, Grid, Snackbar, IconButton, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CustomTextField from '../../CustomTextField';
import CustomButton from '../../CustomButton';

import firebase from '../../../firebase';
import { appContext } from '../../../contexts/app';
import { mainRouterHistory } from "../../../routerHistories";
import CustomNavBar from '../../CustomNavBar';

const useStyles = makeStyles({
    typography: {
        color: '#979797',
        fontSize: 16,
        marginTop: 15
    },
    custNackbar: {
        '& .MuiSnackbarContent-root': {
            background: 'rgba(157, 205, 42, 0.13)',
            color: '#7DA124',
            marginTop: '15%',
            borderRadius: 13
        }
    }
});


function ForgotPassword() {
    const classes = useStyles();
    const { error } = useContext(appContext);

    const [email, setEmail] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [emailValidate, setEmailValidate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorField, setErrorField] = useState(false);

    const handleClose = (event, reason) => {
        setOpenSnackbar(false);
        mainRouterHistory.push("/");
    };

    const onChangeEmail = (event) => {
        const caracteres = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const t = event.currentTarget.value.split(" ");
        let text = "";
        t.map((r) => {
            if (r !== "") {
                text += r;
            }
        });
        setEmail(text.toLowerCase());
        if (text !== "") {
            if (caracteres.test(text)) {
                setEmailValidate(false);
            } else {
                setEmailValidate(true);
            }
        } else {
            setEmailValidate(true);
        }
    };

    const subminForm = () => {
        setLoading(true);
        if (email === '') {
            setErrorField(true)
            setLoading(false);
        } else {
            firebase
                .auth()
                .sendPasswordResetEmail(email)
                .then(result => {
                    setOpenSnackbar(true)
                    setLoading(false);
                })
                .catch(err => {
                    error.setMessage(err.message);
                    setLoading(false);
                });
        }
    }

    return (
        <div>
            <CustomNavBar/>
            <Grid
                container
                style={{ marginTop: 30 }}
                justifyContent='center'
            >
                <Grid
                    item
                    xs={11} sm={7} md={5} lg={3}
                    style={{ marginTop: 15, fontFamily: 'SF Pro Display' }}
                >
                    <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 20 }}>
                        Forgot Password?
                    </p>
                    <p className={classes.typography}>
                        Enter the email address you used when you joined and we’ll send you
                        instructions to reset your password. <br /><br />
                        For security reasons, we do NOT store your password. So rest assured
                        that we will never send your password via email.
                    </p>
                    <p className={classes.typography} style={{ color: 'black' }}>
                        Email
                    </p>
                    <CustomTextField
                        value={email}
                        type="text"
                        placeholder="your@email.com"
                        error={email !== '' ? emailValidate : errorField}
                        helperText={email && emailValidate ? "Please enter a valid email" : ''}
                        onChange={onChangeEmail}
                    />
                    <div style={{ marginTop: 30 }}>
                        {loading === true ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                            </div> :
                            <CustomButton
                                label='Send'
                                width='full'
                                onClick={subminForm}
                            />
                        }
                    </div>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={4000}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        className={classes.custNackbar}
                        message="You can now check the email to create a new password."
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                </Grid>
            </Grid> 
        </div>
    );
}

export default ForgotPassword;