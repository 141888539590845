import React from "react";
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import FastfoodRoundedIcon from '@material-ui/icons/FastfoodRounded';
import LocalAirportRoundedIcon from '@material-ui/icons/LocalAirportRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import WorkOutlineRoundedIcon from '@material-ui/icons/WorkOutlineRounded';
import WatchLaterRoundedIcon from '@material-ui/icons/WatchLaterRounded';
import AccessibleRoundedIcon from '@material-ui/icons/AccessibleRounded';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import AssignmentLateRoundedIcon from '@material-ui/icons/AssignmentLateRounded';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import PanToolRoundedIcon from '@material-ui/icons/PanToolRounded';

const icons = {
    Assignment: <AssignmentRoundedIcon style={{ color: '#197FE8' }} />,
    DoneOutline: <DoneOutlineRoundedIcon style={{ color: '#197FE8' }} />,
    FastFood: <FastfoodRoundedIcon style={{ color: '#197FE8' }} />,
    LocalAirport: <LocalAirportRoundedIcon style={{ color: '#197FE8' }} />,
    Visibility: <VisibilityRoundedIcon style={{ color: '#197FE8' }} />,
    WorkOutline: <WorkOutlineRoundedIcon style={{ color: '#197FE8' }} />,
    WatchLater: <WatchLaterRoundedIcon style={{ color: '#197FE8' }} />,
    Accessible: <AccessibleRoundedIcon style={{ color: '#197FE8' }} />,
    Grade: <GradeRoundedIcon style={{ color: '#197FE8' }} />,
    HelpOutline: <HelpOutlineRoundedIcon style={{ color: '#197FE8' }} />,
    Search: <SearchRoundedIcon style={{ color: '#197FE8' }} />,
    Build: <BuildRoundedIcon style={{ color: '#197FE8' }} />,
    AssignmentLate: <AssignmentLateRoundedIcon style={{ color: '#197FE8' }} />,
    Face: <HeadsetMicRoundedIcon style={{ color: '#197FE8' }} />,
    HourglassEmpty: <HourglassEmptyRoundedIcon style={{ color: '#197FE8' }} />,
    PanTool: <PanToolRoundedIcon style={{ color: '#197FE8' }} />
};
export const meaningIconIds = Object.keys(icons);

export default function (props) {
    const { iconId } = props;
    if (!icons[iconId]) {
        return icons["none"];
    } else {
        return icons[iconId];
    }
}
