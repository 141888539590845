import React from 'react';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    checkFalse: {
        '& span':{
            color:'#ECEEF2',
        }
    },
    checkTrue: {
        '& span':{
            color:'#197FE8'
        }
    },
}))

const CustomCheckBox = (props) => {
    const { checked } = props;
    const classes = useStyles();

    return (
        <Checkbox
            {...props}
            className={checked === false ? classes.checkFalse : classes.checkTrue}
            style={{ padding: 0 }}
            color="primary"
        />
    )
}
export default CustomCheckBox;