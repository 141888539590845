import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Grid, makeStyles } from '@material-ui/core';
import CustomTextField from '../../CustomTextField';
import CustomButton from '../../CustomButton';

import firebase from '../../../firebase';
import { mainRouterHistory } from "../../../routerHistories";
import { appContext } from '../../../contexts/app';
import CustomCheckBox from '../../CustomCheckBox';
import CustomNavBar from '../../CustomNavBar';

const useStyles = makeStyles({
    typography: {
        textAlign: "left",
        fontSize: 16,
        marginBottom: 6,
        fontWeight: 400,
        color: 'black'
    },
    termsText: {
        color: '#979797',
        marginLeft: 6,
        marginTop: 15,
        fontSize: 14,
        alignItems: 'center'
    }
});


function Register() {
    const classes = useStyles();
    const { error } = useContext(appContext);

    const [form, setForm] = useState({
        email: '',
        password: '',
    });
    const [errorField, setErrorField] = useState(false);
    const [emailValidate, setEmailValidate] = useState("");
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const onChangeEmail = (event) => {
        const caracteres = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const t = event.currentTarget.value.split(" ");
        let text = "";
        t.map((r) => {
            if (r !== "") {
                text += r;
            }
        });
        setForm({ ...form, email: text.toLowerCase() });
        if (text !== "") {
            if (caracteres.test(text)) {
                setEmailValidate(false);
            } else {
                setEmailValidate(true);
            }
        } else {
            setEmailValidate(true);
        }
    };

    const subminForm = () => {
        if (form.email === '' || form.password === '' ||
            (form.password.length >= 1 && form.password.length < 6) || checked === false) {
            setErrorField(true)
        } else {
            firebase
                .auth()
                .createUserWithEmailAndPassword(
                    form.email,
                    form.password
                )
                .then(result => {
                    mainRouterHistory.push(
                        "/dashboard"
                    );
                })
                .catch(err => {
                    error.setMessage(err.message);
                });
            setErrorField(false)
        }

    }

    return (
        <div>
            <CustomNavBar/>
            <Grid
                container
                style={{ marginTop: 30 }}
                justifyContent='center'
            >
                <Grid
                    item
                    xs={11} sm={7} md={5} lg={3}
                    style={{ marginTop: 15, fontFamily: 'SF Pro Display' }}
                >
                    <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 20 }}>
                        Sign up
                    </p>
                    <p className={classes.typography}> Email </p>
                    <CustomTextField
                        value={form.email}
                        placeholder="your@email.com"
                        error={form.email !== '' ? emailValidate : errorField}
                        onChange={onChangeEmail}
                        helperText={form.email && emailValidate ? "Please enter a valid email" : ''}
                    />
                    <p className={classes.typography}> Password </p>
                    <CustomTextField
                        value={form.password}
                        placeholder="6+characters"
                        type="password"
                        error={errorField || (form.password.length >= 1 && form.password.length < 6) ? true : false}
                        helperText={form.password.length >= 1 && form.password.length < 6 ? 'Min 6 caracters' : ''}
                        onChange={e => {
                            setForm({ ...form, password: e.target.value });
                        }}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomCheckBox
                            checked={checked}
                            onClick={handleChange}
                            disabled={false}
                        />
                        <div className={classes.termsText}>
                            Creating an account means you’re okay with our<br />
                            <Link style={{ color: '#197FE8' }} to='/terms-of-service'>
                                Terms of Service, Privacy Policy.
                            </Link>
                        </div>
                    </div>
                    <div style={{ marginTop: 30 }}>
                        <CustomButton
                            label='Sign up'
                            width='full'
                            onClick={subminForm}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                        <p className={classes.typography} style={{ color: '#979797', marginRight: 6, }}>
                            Already a member?
                        </p>
                        <Link
                            to='/'
                            className={classes.typography}
                            style={{ textDecoration: 'none', fontWeight: 600 }}
                        >
                            Sign In
                        </Link>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Register;