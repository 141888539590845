import React, { useState, useContext, forwardRef } from "react";
import {
    Card,
    CardContent,
    CardActions,
    Divider,
    CircularProgress,
    makeStyles,
    IconButton,
    MenuItem,
    Popover
} from "@material-ui/core";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { OfficeContext } from "../OfficeContext";
import { PagingContext } from "./PagingContext";
import { appContext } from "../../../../contexts/app";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

import MeaningIcons, { meaningIconIds } from "./MeaningIcons";

import CustomTextField from "../../../CustomTextField";
import CustomButton from "../../../CustomButton";

const useStyles = makeStyles({
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        fontFamily: 'SF Pro Display',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
    },
    iconSelected: {
        border: '1px solid #ECEEF2',
        height: 48,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 12
    },
    customPopover: {
        '& .MuiPopover-paper': {
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: 280,
            justifyContent: 'center',
            paddingTop: 15,
            paddingBottom: 15,
        },
        '& svg': {
            fontSize: 30
        },
    }
});

const EditMeaningButton = forwardRef((props, ref) => {
    const { handleClose, infoButton } = props;
    const classes = useStyles();

    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const { getPaging } = useContext(PagingContext);

    const [loading, setLoading] = useState(false);
    const [meaningButton, setMeaningButton] = useState({
        ...infoButton
    });

    const subminNewMeaningButton = () => {
        const req = {
            officeId,
            meaningButtonId: infoButton.meaningButtonId,
            ...meaningButton
        };
        setLoading(true);
        insyncHttps.paging
            .updateMeaningButton(req)
            .then(result => {
                setLoading(false);
                getPaging();
                handleClose();
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                error.setMessage(err.message);
            });

    }

    return (
        <Card ref={ref} className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 20 }} className={classes.title}>
                        Meaning buttons
                    </p>
                    <p
                        style={{ marginBottom: 20, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <ModifyMeaningButton
                    meaningButton={meaningButton}
                    handleChange={p => {
                        setMeaningButton({ ...p });
                    }}
                />
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
            </CardContent>
            <CardActions style={{ marginTop: 32, paddingLeft: 25, paddingRight: 25 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        disabled={loading}
                        width='full'
                        label='Save'
                        onClick={subminNewMeaningButton}
                    />
                }
            </CardActions>
        </Card>
    );
});
export default EditMeaningButton

function ModifyMeaningButton(props) {
    const { meaningButton, handleChange } = props;
    const classes = useStyles();
    const [meaningIconColorAnchorEl, setMeaningIconAnchorEl] = useState(null);

    return (
        <div style={{ marginBottom: 46, paddingLeft: 25, paddingRight: 25, fontFamily: 'SF Pro Display' }}>
            <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 30 }}>
                Name
            </p>
            <CustomTextField
                placeholder="Name"
                value={meaningButton.name}
                onChange={e => {
                    meaningButton.name = e.target.value;
                    handleChange(meaningButton);
                }}
            />
            <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 30 }}>
                Icon
            </p>
            <div className={classes.iconSelected}>
                <MeaningIcons
                    iconId={meaningButton.iconId}
                />
                <IconButton
                    onClick={e => {
                        setMeaningIconAnchorEl(e.currentTarget);
                    }}
                >
                    <ExpandMoreRoundedIcon style={{ color: '#3A3E4B' }} />
                </IconButton>
            </div>
            <Popover
                className={classes.customPopover}
                anchorEl={meaningIconColorAnchorEl}
                open={Boolean(meaningIconColorAnchorEl)}
                onClose={() => {
                    setMeaningIconAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {meaningIconIds.map(iconId => {
                    return (
                        <MenuItem
                            selected={
                                iconId &&
                                iconId ===
                                meaningButton.iconId
                            }
                            key={iconId}
                            onClick={() => {
                                meaningButton.iconId = iconId;
                                handleChange(meaningButton);
                                setMeaningIconAnchorEl(
                                    null
                                );
                            }}
                        >
                            <MeaningIcons
                                iconId={iconId}
                            />
                        </MenuItem>
                    );
                })}
            </Popover>
        </div>

    );
}