import React, { useContext, useState } from "react";
import {
   IconButton,
   CircularProgress,
   Dialog,
   Modal,
   makeStyles
} from "@material-ui/core";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';
import DesktopMacRoundedIcon from '@material-ui/icons/DesktopMacRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';

import { BillingContext } from "../BillingContext";
import AddSubscription from "./AddSubscription";
import SubscriptionDetails from "./SubscriptionDetails";
import ErrorStateDisplay from "../../../../ErrorStateDisplay/ErrorStateDisplay";

const useStyles = makeStyles({
   infoContainer: {
      borderRadius: 4,
      paddingTop: 34,
      paddingBottom: 34,
      paddingLeft: 30,
      paddingRight: 26,
      background: 'white',
      border: '1px solid #EDEEF0',
      marginTop: 30,
      userSelect: 'none',
      fontFamily: 'SF Pro Display'
   },
   title: {
      fontSize: 24,
      fontWeight: 'bold',
      margin: 0,
      wordBreak: 'break-all'
   },
   subTitle: {
      fontSize: 16,
      fontWeight: 'normal',
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 0,
      color: '#979797'
   },
   numberSub: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 25,
      width: 25,
      background: '#F7F8F9',
      color: '#9DA2B4',
      borderRadius: 15,
      marginRight: 10
   }
});

export default function Subscriptions(props) {
   const classes = useStyles();
   const { subscriptions } = useContext(BillingContext);
   const { subs, loading } = subscriptions;
   const [addSubDialogOpen, setAddSubDialogOpen] = useState(false);
   const [viewedSubscription, setViewedSubscription] = useState(null);

   let content;

   if (subscriptions.error) {
      content = (
         <ErrorStateDisplay
            title="Error Getting Subscriptions"
            message={subscriptions.error}
            retry={subscriptions.getSubs}
         />
      );
   } else {
      content = (
         <React.Fragment>
            {loading ? (
               <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center' }}>
                  <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
               </div>
            ) : (
               <div>
                  {subs
                     .sort((a, b) => {
                        const ranks = {
                           active: 3,
                           non_renewing: 2
                        };
                        if (a.status !== b.status) {
                           if (
                              (ranks[a.status] || 0) >
                              (ranks[b.status] || 0)
                           ) {
                              return -1;
                           } else {
                              return 1;
                           }
                        } else {
                           if (a.id > b.id) {
                              return 1;
                           } else {
                              return -1;
                           }
                        }
                     })
                     .map(sub => {
                        return (
                           <div
                              key={sub.id}
                              style={{
                                 display: 'flex',
                                 justifyContent: 'space-between',
                                 alignItems: 'center',
                                 paddingBottom: 10,
                                 marginTop: 10,
                                 paddingTop: 10,
                                 borderTop: '1px solid #EDEEF0',
                              }}
                           >
                              <div style={{ display: 'flex', flexDirection: 'column', height: 60 }}>
                                 <p className={classes.subTitle}>{sub.plan_id}</p>
                                 <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <DesktopMacRoundedIcon style={{ color: '#979797' }} />
                                    <div className={classes.numberSub}>
                                       {sub.plan_quantity}
                                    </div>
                                    <div style={{ margin: 0, }}>
                                       {(
                                          sub.plan_unit_price / 100
                                       ).toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD"
                                       })} =&nbsp;
                                       <strong>
                                          {(
                                             sub.plan_amount / 100
                                          ).toLocaleString("en-US", {
                                             style: "currency",
                                             currency: "USD"
                                          })}
                                       </strong>
                                    </div>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', height: 60, alignItems: 'center' }}>
                                 <span
                                    style={{
                                       color: sub.status === "active" ? "#9DCD2A" : "#EC5829"
                                    }}
                                 >
                                    {sub.status}
                                 </span>
                                 <IconButton
                                    style={{ height: 40, width: 40 }}
                                    onClick={() => {
                                       setViewedSubscription(sub);
                                    }}
                                 >
                                    <CreateRoundedIcon style={{ color: '#9DA2B4' }} />
                                 </IconButton>
                              </div>


                           </div>
                        );
                     })}
               </div>
            )}
            <Modal
               open={viewedSubscription != null}
               style={{
                  justifyContent: "center",
                  justifyItems: "center",
                  display: "flex"
               }}
            >
               <SubscriptionDetails
                  handleClose={() => {
                     setViewedSubscription(null);
                  }}
                  subscription={viewedSubscription}
               />
            </Modal>
            <Dialog
               open={addSubDialogOpen}
            >
               <AddSubscription
                  handleClose={() => {
                     setAddSubDialogOpen(false);
                  }}
               />
            </Dialog>
         </React.Fragment>
      );
   }
   return (
      <div className={classes.infoContainer}>
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p className={classes.title}>
               Subscriptions
            </p>
            <div>
               <IconButton
                  onClick={() => { setAddSubDialogOpen(true) }}
                  style={{ background: 'rgba(157, 162, 180, 0.08)', padding: 5, height: 40, width: 40 }}
               >
                  <AddRoundedIcon style={{ color: '#9DA2B4' }} fontSize='medium' />
               </IconButton>
               <IconButton
                  onClick={() => { subscriptions.getSubs() }}
                  style={{ padding: 5, marginRight: 10, height: 40, width: 40 }}
               >
                  <AutorenewRoundedIcon style={{ color: '#9DA2B4' }} fontSize='medium' />
               </IconButton>
            </div>
         </div>
         {content}
      </div>
   );
}
