import React from 'react';
import {
    TextField,
    InputAdornment,
    IconButton,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    customTextField: {
        width: '100%',
        borderRadius: 4,
        border: 'none',
        fontFamily: 'SF Pro Display',
        '& input': {
            boxShadow: 'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',
            backgroundColor: 'white',
            borderRadius: 4,
            fontSize: 17,
            padding: 16,
            borderColor: '#ECEEF2',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ECEEF2',
        },
        '& .MuiOutlinedInput-root.Mui-error': {
            color: '#EC5829',
            '& input': {
                background: '#FDEBE3',
                borderRadius: 4,
                fontSize: 17,
                padding: 16,
                borderColor: '#ECEEF2',
            },
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EC5829'
        },
    },
    customMultiLine: {
        width: '100%',
        background: 'blue',
        fontSize: 17,
        borderRadius: 4,
        '& .MuiOutlinedInput-input': {
            color: '#000',
            fontWeight: 400,
            fontSize: 17,
            '&.Mui-disabled': {
                color: 'gray'
            },
            '&::-webkit-scrollbar': {
                width: 8,
                height: 0,
            },
            '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#c3c3c3',
                borderRadius: 2,
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#888',
                borderRadius: 2,
            },
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EC5829'
        },
        '& > div': {
            borderRadius: 4,
            fontSize: 17,
            padding: 17
        },
        '& textarea': {

            fontSize: 17
        }
    },
    icon: {
        '& > svg': {
            fontSize: 30,
        },
    },
    iconDisabled: {
        '& > svg': {
            fontSize: 30,
        },
    },
}))


const CustomTextField = (props) => {
    const { iconstart, iconend, onClick, className, disabled, rows, style, multiline, minLength, maxLength, inputRef, onKeyPress } = props;
    const classes = useStyles();
    const [mouseHover, setMouseHover] = React.useState(true)
    const typeInput = className === 'multiline' ? classes.customMultiLine : classes.customTextField

    return (
        <TextField
            {...props}
            style={style}
            className={typeInput}
            multiline={multiline}
            rows={rows}
            onKeyPress={onKeyPress}
            inputRef={inputRef}
            variant="outlined"
            focused={false}
            onMouseOver={() => setMouseHover(false)}
            onMouseLeave={() => setMouseHover(true)}

            InputProps={{
                startAdornment: iconstart ? (
                    <InputAdornment
                        position="start"
                        className={mouseHover ? classes.iconDisabled : classes.icon}
                    >
                        {iconstart}
                    </InputAdornment>
                ) : null,
                endAdornment: iconend ? (
                    <IconButton
                        onClick={onClick}
                        disabled={disabled}
                    >
                        <InputAdornment
                            position="end"
                            className={disabled === true ? classes.icon : classes.iconDisabled}
                        >
                            {iconend}
                        </InputAdornment>
                    </IconButton>
                ) : null,
            }}
            inputProps={{
                maxLength,
                minLength,
                min: 1,
            }}
        />
    );
}

export default CustomTextField;
