import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import MomentUtils from '@date-io/moment';
import {
    Card,
    Divider,
    CardContent,
    makeStyles,
    CircularProgress
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import CustomTextField from "../../../../CustomTextField";
import CustomButton from "../../../../CustomButton";

import { BillingContext } from "../BillingContext";
import { appContext } from "../../../../../contexts/app";
import { OfficeContext } from "../../OfficeContext";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";

const useStyles = makeStyles({
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797'
    },
    textField: {
        fontSize: 16,
        marginBottom: 10,
        marginTop: 20,
        textAlign: 'left',
    },
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        fontFamily: 'SF Pro Display',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    customPopover: {
        '& .MuiPickersToolbar-toolbar': {
            background: 'linear-gradient(180deg, #B5E346 0%, #9FCF2C 100%)',
        },
        '& .MuiPickersBasePicker-pickerView': {
            '& .MuiPickersMonth-monthSelected': {
                color: '#B5E346',
            },
            '& .MuiPickersYear-yearSelected': {
                color: '#B5E346',
            },
            '& .MuiButton-textPrimary': {
                color: '#B5E346',
            }
        },
        '& .MuiDialogActions-root': {
            '& .MuiButton-textPrimary': {
                color: '#B5E346',
            }
        },
    },
});

export default React.forwardRef((props, ref) => {
    const classes = useStyles();
    const { dataCard, handleClose } = props;
    const { officeId } = useContext(OfficeContext);
    const { paymentSources } = useContext(BillingContext);
    const { error } = useContext(appContext);

    const [selectedDate, handleDateChange] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [errorField, setErrorField] = useState(false);
    const [form, setForm] = useState({
        fullName: '',
        cardNumber: '',
        cvv: '',
    });

    const year = moment(selectedDate).format('YYYY')
    const month = moment(selectedDate).format('M')

    useEffect(() => {
        setForm({
            ...form,
            cardNumber: dataCard.card.masked_number,
            fullName: dataCard.card.first_name
        })
        
        const date = moment(`${dataCard.card.expiry_year}${dataCard.card.expiry_month}`, moment.defaultFormat).toDate()
        handleDateChange(date)

    }, [dataCard]);

    const onChangeText = (prop) => (event) => {
        setForm({ ...form, [prop]: event.target.value })
    };

    const subminDataCardUpdate = () => {
        setLoading(true);
        if (form.cardNumber === '' || form.fullName === '') {
            setErrorField(true)
            setLoading(false);
        } else {
            insyncHttps.billing
                .updatePaymentSource({
                    officeId,
                    fullName: form.fullName,
                    expiry_month: month,
                    expiry_year: year,
                    id: dataCard.id
                })
                .then(result => {
                    paymentSources.getSources();
                    setLoading(false);
                    handleClose();
                })
                .catch(err => {
                    error.setMessage(err.message);
                    setLoading(false);
                    console.error(error);
                });
        }
    }

    return (
        <Card ref={ref} className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 23 }} className={classes.title}>
                        Payment sources
                    </p>
                    <p
                        style={{ marginBottom: 23, cursor: 'pointer' }}
                        className={classes.subTitle}
                        onClick={handleClose}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <div style={{ marginBottom: 38, paddingLeft: 25, paddingRight: 25 }}>
                    <p className={classes.textField} style={{ marginTop: 31 }}>
                        Name
                    </p>
                    <CustomTextField
                        value={form.fullName}
                        placeholder="Name"
                        onChange={onChangeText('fullName')}
                        error={form.fullName === '' ? errorField : null}
                    />
                    <p className={classes.textField}>
                        Card number
                    </p>
                    <CustomTextField
                        value={form.cardNumber}
                        placeholder="Card number"
                        onChange={onChangeText('cardNumber')}
                        error={form.cardNumber === '' ? errorField : null}
                        disabled={true}
                    />
                    <p className={classes.textField} >
                        MM/YY
                    </p>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            views={["year", "month"]}
                            minDate={new Date()}
                            value={selectedDate}
                            inputVariant="outlined"
                            focused={false}
                            DialogProps={{ className: classes.customPopover }}
                            style={{width:'100%'}}
                            onChange={handleDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <Divider style={{ marginBottom: 32 }} />
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                        <CustomButton
                            label='Update Card'
                            width='full'
                            onClick={subminDataCardUpdate}
                        />
                    </div>
                }
            </CardContent>
        </Card>
    );
});
