import { createTheme } from "@material-ui/core/styles";

// const insyncColors = ["#EC5829", "#9DCD2A", "#FDEBE3", "#F86F46", "#F8F9FB", "#E0E0E0", "#979797", "#197FE8", "#FFFFFF", "#3A3E4B"];

export const theme = createTheme({
    palette: {
        // primary: {
           
        // },
    },
});
