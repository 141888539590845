import firebase from "../../firebase";

export default function({ email }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "user",
        funcName: "doesUserExist",
        data: {
            email
        }
    });
}
