import { createContext, useState } from "react";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export const PatientTrackingContext = createContext({
    rooms: {},
    stages: {},
    loading: false,
    getPatientTracking: () => {},
    error: null
});

export const usePatientTracking = officeId => {
    const [rooms, setRooms] = useState({});
    const [stages, setStages] = useState({});
    const [defaults, setDefaults] = useState({});
    const [visitDisplaySettings, setVisitDisplaySettings] = useState({});
    const [visitSettings, setVisitSettings] = useState({});
    const [columnLayout, setColumnLayout] = useState({});
    const [columnSortData, setColumnSortData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [visitLate, setVisitLate] = useState({});

    const getPatientTracking = () => {
        setError(null);
        setLoading(true);
        insyncHttps.patientTracking
            .getPatientTracking({ officeId })
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.data) {
                    setRooms(result.data.rooms);
                    setStages(result.data.stages);
                    setDefaults(result.data.defaults || {});
                    setVisitDisplaySettings(
                        result.data.visitDisplaySettings || {}
                    );
                    setVisitSettings(result.data.visitSettings || {});
                    setColumnLayout(result.data.trackerColumns);
                    setColumnSortData(result.data.sortData || {});
                    setVisitLate(result.data.visitLate || {});
                }
            })
            .catch(error => {
                setError(error.message);
                console.error(error);
                setLoading(false);

                setRooms({});
                setStages({});
                setDefaults({});
                setVisitDisplaySettings({});
                setVisitSettings({});
                setColumnLayout({});
                setColumnSortData([]);
                setVisitLate({});
            });
    };

    return {
        rooms,
        stages,
        defaults,
        visitDisplaySettings,
        visitSettings,
        loading,
        getPatientTracking,
        columnLayout,
        error,
        columnSortData,
        visitLate
    };
};
