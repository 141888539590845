import { useContext } from "react";
import { PatientTrackingContext } from "../PatientTrackingContext";
import firebase from "../../../../../firebase";
import { OfficeContext } from "../../OfficeContext";

export default function() {
    const patientTracking = useContext(PatientTrackingContext);
    const { officeId } = useContext(OfficeContext);

    function addColumnDataSort (formSort) {

        const req = {
            funcName: "addColumnDataSort",
            funcRole: "patientTrackingAdmin",
            data: { officeId, sortData: formSort }
        }
        firebase.functions().httpsCallable("allFunctions")(req).then(()=>{
            patientTracking.getPatientTracking()
        })

    }

    return { addColumnDataSort };
}
