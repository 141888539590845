import firebase from "../../firebase";

export default function({ officeId, roomId }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingAdmin",
        funcName: "removeRoomFromOffice",
        data: {
            officeId,
            roomId
        }
    });
}
