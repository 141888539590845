import React, { useContext, useEffect, useState } from 'react';

import { makeStyles, Divider, CircularProgress } from '@material-ui/core';

import insyncHttps from "../../../../../insyncHttps/insyncHttps";
import { appContext } from "../../../../../contexts/app";
import { PatientTrackingContext } from "../PatientTrackingContext";
import { OfficeContext } from "../../OfficeContext";

import CustomTextField from '../../../../CustomTextField';
import CustomButton from '../../../../CustomButton';
import CustomRadioCheck from '../../../../CustomRadioCheck';


const useStyles = makeStyles({
    infoContainer: {
        background: 'white',
        borderRadius: 4,
        marginTop: 21,
        maxWidth: 480,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'SF Pro Display',
        border: '1px solid #EDEEF0',
        padding: 30,
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
        marginBottom: 15
    },
    subtitle: {
        fontSize: 16,
        margin: 0,
        wordBreak: 'break-all',
        marginBottom: 7,
        marginTop: 21
    },
});

function VisitSettings() {
    const classes = useStyles();
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const { defaults, getPatientTracking, loading, visitSettings } = useContext(
        PatientTrackingContext
    );

    const [requesting, setRequesting] = useState(false);
    const [appointmentLength, setAppointmentLength] = useState(
        parseInt(defaults.appointmentLength) || 30
    );
    const [countFrom, setCountFrom] = useState(
        visitSettings.countFrom || "appointmentTime"
    );

    useEffect(() => {
        setAppointmentLength(parseInt(defaults.appointmentLength) || 30);
        setCountFrom(visitSettings.countFrom || "appointmentTime");
    }, [defaults, visitSettings]);

    const updateVisitSetting = (countFrom) => () => {
        setRequesting(true);
        insyncHttps.patientTracking
            .updateVisitSettings({
                officeId,
                appointmentLength,
                countFrom
            })
            .catch(e => {
                setRequesting(false);
                error.setMessage(e);
            })
            .then(e => {
                setRequesting(false);
                getPatientTracking();
            });
    }

    const sendUpdateVisitSetting = () => {
        setRequesting(true);
        insyncHttps.patientTracking
            .updateVisitSettings({
                officeId,
                appointmentLength,
                countFrom
            })
            .catch(e => {
                setRequesting(false);
                error.setMessage(e);
            })
            .then(e => {
                setRequesting(false);
                getPatientTracking();
            });
    }

    return (
        <div className={classes.infoContainer}>
            <p className={classes.title}>
                Visit Settings
            </p>
            <Divider style={{ backgroundColor: '#EDEEF0' }} />
            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                <div>
                    <p className={classes.subtitle}>
                        Default Appt Lenght
                    </p>
                    <CustomTextField
                        type="number"
                        value={appointmentLength}
                        onChange={e => {
                            setAppointmentLength(e.target.value);
                        }}
                    />
                    <p className={classes.subtitle}>
                        Count From
                    </p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomRadioCheck
                            checked={countFrom === "appointmentTime"}
                            onChange={updateVisitSetting('appointmentTime')}
                        />
                        <p style={{ margin: 8, color: countFrom === "appointmentTime" ? '#197FE8' : 'black' }}>
                            Appointment time
                        </p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <CustomRadioCheck
                            checked={countFrom === "checkInTime"}
                            onChange={updateVisitSetting('checkInTime')}
                        />
                        <p style={{ margin: 8, color: countFrom === "checkInTime" ? '#197FE8' : 'black' }}>
                            Check-in time
                        </p>
                    </div>
                    {requesting ?
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                            <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                        </div>
                        :
                        <div style={{ marginTop: 20, minWidth: 143 }}>
                            <CustomButton
                                label='Update'
                                onClick={sendUpdateVisitSetting}
                            />
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default VisitSettings;
