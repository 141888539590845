import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    customGeneral: {
        textTransform: 'uppercase',
        minWidth: 110,
        padding: '6px 16px',
        fontWeight: 'bold',
        fontSize: 15,
        fontStyle: 'normal',
        borderRadius: 8,
        color: 'white',
        fontFamily: 'SF Pro Display',
        '&:disabled': {
            background: '#979797 !important',
        }
    }
}))


const CustomButton = (props) => {
    const { label, disabled, width, onClick, endIcon, backgroundred } = props;
    const classes = useStyles();
    const sizeButtonWidth = width === 'full' ? '100%' : 'fit-content';

    return (
        <Button
            {...props}
            className={classes.customGeneral}
            style={{ width: sizeButtonWidth, height: 48, background: backgroundred ? backgroundred : 'linear-gradient(180deg, #B5E346 0%, #9FCF2C 100%) ' }}
            disabled={disabled}
            disableElevation={true}
            disableRipple={false}
            onClick={onClick}
            variant='contained'
            endIcon={endIcon}
        >
            {label}
        </Button>
    );
}

export default CustomButton;
