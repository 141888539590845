import React, { useState, useContext, useEffect } from "react";
import {
    Card,
    CardContent,
    IconButton,
    CardActions,
    makeStyles,
    CircularProgress,
    Divider
} from "@material-ui/core";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

// import CustomTextField from "../../../CustomTextField";
import CustomCheckBox from "../../../CustomCheckBox";
import CustomButton from "../../../CustomButton";

import { UsersContext } from "./UsersContext";
import { appContext } from "../../../../contexts/app";
import { OfficeContext } from "../OfficeContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

const useStyles = makeStyles({
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797'
    },
    textField: {
        fontSize: 16,
        marginBottom: 10,
        marginTop: 20,
        textAlign: 'left',
    },
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        fontFamily: 'SF Pro Display',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
});

export default React.forwardRef((props, ref) => {
    const classes = useStyles();
    const { user, handleClose } = props;
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const users = useContext(UsersContext);

    // const [errorField, setErrorField] = useState(false);
    const [loading, setLoading] = useState(false);
    const [arrRol, setArrRol] = useState([]);
    const [form, setForm] = useState({
        name: '',
        email: user.user.email,
        selectedRole: '',
    });

    const arrayRoles = [
        {
            text: 'Billing Admin',
            name: 'billingAdmin',
            value: ''
        },
        {
            text: 'Office User',
            name: 'officeUser',
            value: ''
        },
        {
            text: 'Office Admin',
            name: 'officeAdmin',
            value: ''
        }
    ];

    useEffect(() => {
        if (user && user.user && user.user.roles) {
            const arr = user.user.roles.map(t => t);
            const data = arrayRoles.map(e => ({
                ...e,
                show: arr.includes(e.name),
                data: user.user.roles.filter(resp => resp === e.name),
                check: false
            }))
            setArrRol(data)
        }
    }, [user])

    const handleChange = (item) => (event) => {
        const newAdd = arrRol.map(e => ({
            ...e,
            check: e.name === item.name ? event.target.checked : false
        }))
        setArrRol(newAdd)
        setForm({ ...form, selectedRole: item.name })
    };

    const deleteRole = (item) => () => {
        setLoading(true);
        insyncHttps.insyncUser
            .removeOfficeRoleFromUser({
                officeId: officeId,
                email: user.user.email,
                roleName: item
            })
            .then(result => {
                users.getUsers();
                setLoading(false);
                handleClose();
            })
            .catch(err => {
                error.setMessage(err.message);
                setLoading(false);
                console.error(err);
                handleClose();
            });
    }

    const subminUpdateUser = () => {
        setLoading(true);
        insyncHttps.insyncUser
            .addOfficeRoleToUser({
                // fullName: form.name,
                officeId,
                email: form.email,
                roleName: form.selectedRole
            })
            .then(result => {
                setLoading(false);
                users.getUsers();
                handleClose();
            })
            .catch(err => {
                setLoading(false);
                error.setMessage(err.message);
            });
    }

    return (
        <Card className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 20 }} className={classes.title}>
                        Edit user
                    </p>
                    <p
                        style={{ marginBottom: 20, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }}/>
                <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                    <div className={classes.textField}>
                        Email:&nbsp;
                        <strong>
                            {form.email}
                        </strong>
                    </div>
                    {/* <p className={classes.textField}>
                        Full name
                        </p>
                        <CustomTextField
                        value={form.name}
                        placeholder="Member Name"
                        error={form.name === '' ? errorField : false}
                        onChange={e => {
                            setForm({ ...form, name: e.target.value });
                        }}
                    /> */}
                </div>
                <p
                    className={classes.title}
                    style={{ marginLeft: 25, marginTop: 25, fontSize: 16 }}
                >
                    Roles
                </p>
                <div style={{ marginBottom: 20, paddingLeft: 25, paddingRight: 25 }}>
                    {arrRol.map(role => {
                        return (
                            <div key={role.name}>

                                {role.show ?
                                    <div>
                                        <span
                                            style={{ color: '#2F80ED' }}
                                        >
                                            {role.text}
                                        </span>
                                        <IconButton
                                            style={{ padding: 5, height: 40, width: 40, marginLeft: 10 }}
                                            onClick={deleteRole(role.name)}
                                        >
                                            <DeleteRoundedIcon style={{ color: '#9DA2B4' }} />
                                        </IconButton>
                                    </div>
                                    :
                                    <div>
                                        <CustomCheckBox
                                            onClick={handleChange(role)}
                                            checked={role.check}
                                            name={role.name}
                                        />
                                        <span
                                            style={{ color: form.officeUser === true ? '#2F80ED' : '#979797' }}
                                        >
                                            {role.text}
                                        </span>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            </CardContent>
            <Divider style={{ backgroundColor: '#EDEEF0' }}/>
            <CardActions style={{ marginTop: 32, paddingLeft: 25, paddingRight: 25 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 15 }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        label='Update user'
                        // disabled={!form.selectedRole}
                        width='full'
                        onClick={subminUpdateUser}
                    />
                }
            </CardActions>
        </Card>
    );
});
