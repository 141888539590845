import firebase from "../../firebase";

export default function({ subscriptionId, officeId }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "billingAdmin",
        funcName: "cancelSubscription",
        data: {
            subscriptionId,
            officeId
        }
    });
}
