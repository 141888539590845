import React, { useState, useContext, useEffect, forwardRef } from "react";
import { SketchPicker } from "react-color";
import Input from 'react-phone-number-input/input'
import { isEmpty } from "ramda";
import {
    Card,
    CardContent,
    CircularProgress,
    CardActions,
    MenuItem,
    Popover,
    makeStyles,
    Divider,
    IconButton
} from "@material-ui/core";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';

import soundEffects from "../../../../audio/soundEffects";
import { playSoundEffect } from "../../../../audio/audio";

import { usePaging } from "../Paging/PagingContext";
import { MessagingContext } from "./MessagingContext";
import { OfficeContext } from "../OfficeContext";
import { appContext } from "../../../../contexts/app";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

import CustomTextField from "../../../CustomTextField";
import CustomRadioCheck from "../../../CustomRadioCheck";
import CustomButton from "../../../CustomButton";


const useStyles = makeStyles({
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797'
    },
    textField: {
        fontSize: 16,
        marginBottom: 6,
        marginTop: 15,
        textAlign: 'left',
    },
    cardContainer: {
        margin: "auto",
        fontFamily: 'SF Pro Display',
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 450,
        maxWidth: 500,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    selected: {
        border: '1px solid #ECEEF2',
        height: 48,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 12,
    },
    customPopover: {
        '& .MuiPopover-paper': {
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: 280,
            justifyContent: 'center',
            paddingTop: 15,
            paddingBottom: 15,
        },
    },
    boxInputPhone: {
        fontFamily: 'SF Pro Display',
        color: 'currentColor',
        border: '1px solid #ECEEF2',
        fontSize: 16,
        opacity: '0.5',
        boxShadow: 'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 16,
        width: '93%',
        '&:focus-visible': {
            outline: 'none'
        }
    },
});

export default forwardRef((props, ref) => {
    const classes = useStyles();
    const { handleClose, dataUser } = props;
    const { getMessaging } = useContext(MessagingContext);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const paging = usePaging(officeId);
    // -----------------------------------------
    const [formGroup, setFormGroup] = useState({
        name: '',
        lastName: '',
        initials: '',
        passwordProtected: false,
        password: '',
        bgColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        bgColorPaging: '',
        phoneNumber: '',
        soundId: '',
        passwordNew: ''
    });
    const [loading, setLoading] = useState(false);
    const [errorField, setErrorField] = useState(false);
    // -----------------------------------------

    useEffect(() => {
        paging.getPaging();
    }, []);

    useEffect(() => {
        if (!isEmpty(dataUser)) {
            setFormGroup({
                ...dataUser,
                bgColor: dataUser && dataUser.bgColor ? dataUser.bgColor : `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                passwordNew: ''
            })
        }
    }, []);

    // -----------------------------------------

    const handleChange = (event) => {
        if (event.target.name === 'openchat') {
            setFormGroup({ ...formGroup, passwordProtected: false, passwordNew: '' });
        } else {
            setFormGroup({ ...formGroup, passwordProtected: true });
        }
    }
    const handleChangeColor = (color) => {
        setFormGroup({ ...formGroup, bgColor: color });
    }

    const editUser = () => {
        setLoading(true);
        if (formGroup.name === '' || formGroup.name === null || formGroup.lastName === '' || formGroup.lastName === null ||
            formGroup.soundId === '' || formGroup.initials === '' || formGroup.initials === null ||
            (formGroup.passwordProtected && formGroup.passwordNew === '')
        ) {
            setErrorField(true)
            setLoading(false);
        } else {
            insyncHttps.messaging
                .updateUser({
                    ...formGroup,
                    officeId,
                    userId: dataUser.id,
                })
                .then(result => {
                    setLoading(false);
                    getMessaging();
                    handleClose();
                })
                .catch(err => {
                    setLoading(false);
                    console.error(err);
                    error.setMessage(err.message);
                });
        }
    };

    return (
        <Card ref={ref} className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 20 }} className={classes.title}>
                        Edit user
                    </p>
                    <p
                        style={{ marginBottom: 20, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <div style={{ paddingLeft: 25, paddingRight: 25, marginBottom: 46 }}>
                    <ModifyColorSoundUser
                        formGroup={formGroup}
                        handleChangeColor={handleChangeColor}
                        handleChange={formGroup => {
                            const newformGroups = { ...formGroup };
                            setFormGroup(newformGroups);
                        }}
                        errorField={errorField}
                    />
                    <p className={classes.textField}>Name</p>
                    <CustomTextField
                        value={formGroup.name}
                        placeholder='Name user'
                        error={formGroup.name === '' || formGroup.name === null ? errorField : false}
                        onChange={e => {
                            setFormGroup({
                                ...formGroup,
                                name: e.target.value,
                                initials: `${e.target.value.slice(0, 1)}${formGroup.lastName.slice(0, 1)}`
                            });
                        }}
                    />
                    <p className={classes.textField}>Last name</p>
                    <CustomTextField
                        value={formGroup.lastName}
                        error={formGroup.lastName === '' || formGroup.lastName === null ? errorField : false}
                        placeholder='Last name user'
                        onChange={e => {
                            setFormGroup({
                                ...formGroup,
                                lastName: e.target.value,
                                initials: `${formGroup.name.slice(0, 1)}${e.target.value.slice(0, 1)}`
                            });
                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className={classes.textField}>Initials*</p>
                        <p className={classes.textField} style={{ color: '#979797' }}>
                            This is how it will show
                        </p>
                    </div>
                    <CustomTextField
                        value={formGroup.initials}
                        placeholder='Initials user'
                        onChange={e => {
                            setFormGroup({ ...formGroup, initials: e.target.value });
                        }}
                        maxLength={2}
                        error={formGroup.initials === '' || formGroup.initials === null ? errorField : false}
                    />
                    <p className={classes.textField}>Phone</p>
                    <Input
                        country="US"
                        placeholder="(000) 000-0000"
                        value={formGroup.phoneNumber}
                        onChange={e => {
                            setFormGroup({ ...formGroup, phoneNumber: e });
                        }}
                        maxLength={14}
                        className={classes.boxInputPhone}
                    />
                    <div style={{ display: 'flex', marginTop: 22 }}>
                        <CustomRadioCheck
                            text='Private chat'
                            style={{ marginRight: 23 }}
                            onChange={handleChange}
                            checked={formGroup.passwordProtected}
                            name='privatechat'
                        />
                        <CustomRadioCheck
                            text='Open chat'
                            onChange={handleChange}
                            name='openchat'
                            checked={!formGroup.passwordProtected}
                        />
                    </div>
                    <p className={classes.textField}>Password</p>
                    <CustomTextField
                        disabled={!formGroup.passwordProtected}
                        value={formGroup.passwordNew}
                        placeholder='******'
                        type='password'
                        onChange={e => {
                            setFormGroup({ ...formGroup, passwordNew: e.target.value });
                        }}
                        error={formGroup.passwordProtected && (formGroup.passwordNew === '' || formGroup.passwordNew === null) ? errorField : false}
                    />
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
            </CardContent>
            <CardActions style={{ marginTop: 32, paddingLeft: 25, paddingRight: 25 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        width='full'
                        label='Save'
                        onClick={editUser}
                    />
                }
            </CardActions>
        </Card>
    );
});

function ModifyColorSoundUser(props) {
    const { handleChange, formGroup, handleChangeColor } = props;
    const classes = useStyles();
    const [changeColorAnchorEl, setChangeColorAnchorEl] = useState(null);
    const [changeSoundAnchorEl, setChangeSoundAnchorEl] = useState(null);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 14
            }}
        >
            <div style={{ width: '45%' }}>
                <p className={classes.textField}>
                    Color*
                </p>
                <div
                    className={classes.selected}
                    onClick={e => {
                        setChangeColorAnchorEl(e.currentTarget);
                    }}
                >
                    {formGroup.bgColor ?
                        <div style={{ width: '80%', borderRadius: 7, height: 26, background: formGroup.bgColor }} />
                        : <p style={{ color: '#979797', fontSize: 14 }}>Color</p>
                    }
                    <IconButton
                        onClick={e => {
                            setChangeColorAnchorEl(e.currentTarget);
                        }}
                    >
                        <ExpandMoreRoundedIcon style={{ color: '#3A3E4B' }} />
                    </IconButton>
                </div>
                <Popover
                    anchorEl={changeColorAnchorEl}
                    open={Boolean(changeColorAnchorEl)}
                    onClose={() => {
                        setChangeColorAnchorEl(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <SketchPicker
                        width={280}
                        style={{ width: 280 }}
                        color={formGroup.bgColor}
                        onChangeComplete={color => {
                            handleChangeColor(color.hex);
                        }}
                    />
                </Popover>
            </div>
            <div style={{ width: '45%' }}>
                <p className={classes.textField}>
                    Sound*
                </p>
                <div
                    className={classes.selected}
                    onClick={e => {
                        setChangeSoundAnchorEl(e.currentTarget);
                    }}
                >
                    {formGroup.soundId ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <VolumeUpRoundedIcon style={{ color: '#9DA2B4' }} />
                            {formGroup.soundId}
                        </div>
                        : <p style={{ color: '#979797', fontSize: 14 }}>Sound</p>
                    }
                    <IconButton
                        onClick={e => {
                            setChangeSoundAnchorEl(e.currentTarget);
                        }}
                    >
                        <ExpandMoreRoundedIcon style={{ color: '#3A3E4B' }} />
                    </IconButton>
                </div>
                <Popover
                    className={classes.customPopover}
                    anchorEl={changeSoundAnchorEl}
                    open={Boolean(changeSoundAnchorEl)}
                    onClose={() => {
                        setChangeSoundAnchorEl(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {Object.keys(soundEffects).map(soundId => {
                        return (
                            <MenuItem
                                selected={
                                    soundId &&
                                    soundId === formGroup.soundId
                                }
                                key={soundId}
                            >
                                <div >
                                    <div
                                        onClick={() => {
                                            formGroup.soundId = soundId;
                                            handleChange(
                                                formGroup
                                            );
                                            setChangeSoundAnchorEl(
                                                null
                                            );
                                        }}
                                    >
                                        {soundId}
                                    </div>
                                    <div>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                playSoundEffect(
                                                    soundEffects[soundId]
                                                );
                                            }}
                                        >
                                            <VolumeUpRoundedIcon style={{ color: '#197FE8' }} />
                                        </IconButton>
                                    </div>
                                </div>
                            </MenuItem>
                        )
                    })}
                </Popover>
            </div>
        </div>
    );
}
