import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    InputLabel,
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        fontFamily: 'SF Pro Display',
        '& .MuiOutlinedInput-root': {
            borderRadius: 6,
            boxShadow: 'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',
            '& .MuiOutlinedInput-input': {
                padding: 16
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ECEEF2',
                borderWidth: 1,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ECEEF2',
                borderWidth: 1,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ECEEF2',
                borderWidth: 1,
            },
            '&.Mui-disabled': {
                border: '1px !important',
            },
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EC5829'
        },
        '& .MuiOutlinedInput-root.Mui-error': {
            color: '#EC5829',
            background: '#FDEBE3',
            '& input': {
                borderRadius: 4,
                fontSize: 17,
                padding: 16,
                borderColor: '#ECEEF2',
            },
        },
        '& .MuiFormLabel-root': {
            fontWeight: 400,
            fontSize: 16,
            '&.Mui-focused': {
                fontWeight: 500,
            },
            '&.Mui-error': {
                color: '#EC5829',
                fontWeight: 500,
            },
        },
        '& > div .MuiSelect-select': {
            '&:focus': {
                background: 'white',
            },
        },
    },
    optionMenu: {
        background: 'white',
        padding: 10,
        fontWeight: 400,
        fontSize: 12,
        marginLeft: 4,
        marginRight: 8,
        '&:hover': {
            borderRadius: 4,
        },
    },
}));

// interface Props {
//     style?: any;
//     value: any;
//     onChange: (text: any) => void;
//     options: any[];
//     label: string;
//     name: string;
//     disabled?: boolean;
//     error?: boolean;
//     minLength?: number;
//     maxLength?: number;
//     defaultValue?: string;
// }

const CustomSelect = (props) => {

    const classes = useStyles();
    const { value, onChange, options, label, name, error, disabled, minLength, maxLength, defaultValue, empty } = props;

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel error={error} >{label}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                label={label}
                error={error}
                disabled={disabled}
                inputProps={{
                    name: name,
                    id: name,
                    maxLength,
                    minLength,
                }}
                defaultValue={defaultValue}
            >
                {empty ?
                    <MenuItem value="" className={classes.optionMenu}>
                        <em>----</em>
                    </MenuItem>
                    :
                    ''
                }
                {options && options.length > 0 && options.map(option => (
                    <MenuItem key={option.value} value={option.value} className={classes.optionMenu}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CustomSelect;