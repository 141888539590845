import React, { useContext, useState, useEffect } from 'react';

import { makeStyles, IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import { OfficeContext } from "../OfficeContext";
import { usePatientTracking, PatientTrackingContext } from "./PatientTrackingContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import ErrorStateDisplay from "../../../ErrorStateDisplay/ErrorStateDisplay";

import Rooms from './Rooms';
import Stages from './Stages';
import VisitSettings from './VisitSettings';
import SortYourQueues from './SortYourQueues';
import ColumnLayout from './ColumnLayout';
import AdjustAppointment from './AdjustAppointment';

const useStyles = makeStyles({
    infoContainer: {
        background: 'white',
        borderRadius: 4,
        marginTop: 21,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'SF Pro Display',
        border: '1px solid #EDEEF0',
        width: '100%',
        maxWidth: 'none'
    },
    infoCustom: {
        paddingLeft: 33,
        paddingTop: 33,
        paddingRight: 25,
        paddingBottom: 35,
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
    },
    urlContainer: {
        background: '#F0F7FF',
        paddingLeft: 20,
        paddingTop: 7,
        paddingRight: 25,
        paddingBottom: 7,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #CDE4FF',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4
    }
});

function PatientTracking() {
    const classes = useStyles();
    const { officeId } = useContext(OfficeContext);
    const patientTracking = usePatientTracking(officeId);

    const [openCopied, setOpenCopied] = useState(false)
    const [client, setClient] = useState(null);
    const [urlSelector, setUrlSelector] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        patientTracking.getPatientTracking();
        getCustomer()
    }, []);

    if (patientTracking.error) {
        return (
            <ErrorStateDisplay
                title="Error Getting PatientTracking"
                message={patientTracking.error}
                retry={patientTracking.getPatientTracking}
            />
        );
    }

    const getCustomer = () => {
        setLoading(true)
        insyncHttps.billing
            .getCustomer({ officeId })
            .then(result => {
                setClient(result.data.customer);
                setLoading(false)
            })
            .catch(err => {
                setClient(null);
                setLoading(false)
            });
    };

    const office = client && client.meta_data.officeId;
    const copyData = () => {
        // modifying the url of curside check-in depending on the environment
        const cp = `https://insync-2-curbside.web.app/office/${office}`
        navigator.clipboard.writeText(cp)
        setUrlSelector(cp)
        setOpenCopied(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenCopied(false);
    };

    return (

        <PatientTrackingContext.Provider value={patientTracking}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.infoContainer}>
                    <div className={classes.infoCustom}>
                        <p className={classes.title}>
                            Url curbside check-in
                        </p>
                        <p className={classes.subTitle}>
                            With this link the visitors of this office will be able to register
                        </p>
                    </div>
                    <div className={classes.urlContainer}>
                        {loading ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                            </div>

                        ) : (
                            <React.Fragment>
                                <Tooltip
                                    title={
                                        <React.Fragment>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CheckRoundedIcon style={{ color: '#9DCD2A' }} />
                                                <p style={{ fontWeight: 'bold', fontSize: 18, margin: 0 }}>Copied </p>
                                            </div>
                                            <p style={{ fontSize: 14, wordBreak: 'break-all' }}>
                                                {urlSelector}
                                            </p>
                                        </React.Fragment>
                                    }
                                    enterDelay={500}
                                    leaveDelay={200}
                                    open={openCopied}
                                    onClose={handleClose}
                                    arrow
                                >
                                    <div style={{ display: 'flex' }}>
                                        <IconButton onClick={copyData}>
                                            <FileCopyRoundedIcon style={{ color: '#197FE8' }} />
                                        </IconButton >
                                        <p
                                            onClick={copyData}
                                            style={{ color: '#197FE8' }}
                                        >
                                            Url curbside check-in
                                        </p>
                                    </div>
                                </Tooltip>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Rooms />
                    <Stages />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '49%', marginRight: 10, display: 'flex', flexDirection: 'column' }}>
                        <VisitSettings />
                        <SortYourQueues />
                    </div>
                    <div style={{ width: '49%', marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
                        <ColumnLayout />
                        <AdjustAppointment/>
                    </div>
                </div>
            </div>
        </PatientTrackingContext.Provider>
    );
}

export default PatientTracking;
