import React, { useContext, useState } from "react";
import { authContext } from "../../../contexts/auth";
import {
    List,
    ListItem,
    Grid,
    CardContent,
    Card,
    Dialog,
    CardActions,
    ListItemIcon,
    CircularProgress,
    Divider
} from "@material-ui/core";
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';

import { mainRouterHistory } from "../../../routerHistories";
import { DashboardContext } from "./DashboardContext";
import insyncHttps from "../../../insyncHttps/insyncHttps";

import CustomButton from "../../CustomButton";
import CustomTextField from "../../CustomTextField";

export default function () {
    const { officesMeta, getOfficeList, loading } = useContext(
        DashboardContext
    );
    const [createOfficeDialogOpen, setCreateOfficeDialogOpen] = useState(false);
    const [loadingSync, setLoadingSync] = useState(false);

    const customScriptUser = officeId => {
        setLoadingSync(true);
        insyncHttps.office
            .syncUserOffice(officeId)
            .then(result => {
                // console.log('result', result.data.syncUp)
                customScriptUserAttributes(officeId);
            })
            .catch(err => {
                setLoadingSync(false);
            });

    }

    const customScriptUserAttributes = officeId => {
        setLoadingSync(true);
        insyncHttps.office
            .syncUserAttributes(officeId)
            .then(result => {

            })
            .catch(err => {
                setLoadingSync(false);
            });

    }


    return (
        <Card>
            <CardContent>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                ) : (
                    <List>
                        {Object.keys(officesMeta)
                            .map(officeId => {
                                return (
                                    <ListItem
                                        button
                                        divider
                                        key={officeId}
                                        onClick={() => {
                                            customScriptUser(officeId);
                                            mainRouterHistory.push(`/office/${officeId}`, {
                                                officeId: officeId,
                                                officeName:
                                                    officesMeta[officeId].name
                                            });
                                        }}
                                    >
                                        <ListItemIcon style={{ minWidth: 30 }}>
                                            <KeyboardArrowRightRoundedIcon
                                                fontSize="small"
                                                style={{ color: '#9DCD2A' }}
                                            />
                                        </ListItemIcon>
                                        <p style={{ fontSize: 18, margin: 10, fontFamily: 'SF Pro Display' }}>
                                            {officesMeta[officeId].name}
                                        </p>
                                    </ListItem>
                                );
                            })}
                    </List>
                )}
            </CardContent>
            <CardContent>
                <CustomButton
                    label='create office'
                    width='full'
                    onClick={() => {
                        setCreateOfficeDialogOpen(true);
                    }}
                />
            </CardContent>
            <Dialog
                open={createOfficeDialogOpen}
            >
                <Grid container justifyContent="center" align="center">
                    <Grid item xs={12}>
                        <NewOffice
                            getOfficeList={getOfficeList}
                            handleClose={() => {
                                setCreateOfficeDialogOpen(false);
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Card>
    );
}

function NewOffice(props) {
    const auth = useContext(authContext);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const getOfficeList = props.getOfficeList;
    const handleClose = props.handleClose;

    const subminNewOffice = () => {
        setLoading(true);
        insyncHttps.office
            .createOffice({ name })
            .then(async result => {
                await auth.reload();
                setLoading(false);
                getOfficeList();
                handleClose();
            })
            .catch(err => {
                setLoading(false);
            });

    }

    return (
        <Card style={{ margin: "auto", padding: 30, minWidth: 400 }}>
            <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 20 }}>
                        Create Office
                    </p>
                    <p
                        style={{ fontSize: 16, marginBottom: 20, color: '#979797', cursor: 'pointer' }}
                        onClick={handleClose}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <div style={{ marginBottom: 20 }}>
                    <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left' }}>
                        Office name
                    </p>
                    <CustomTextField
                        value={name}
                        placeholder="Add a name for your office"
                        onChange={e => {
                            setName(e.target.value);
                        }}
                    />
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
            </CardContent>
            <CardActions style={{ marginTop: 20 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        label='Create Office'
                        onClick={subminNewOffice}
                        width='full'
                    />
                }
            </CardActions>
        </Card>
    );
}
