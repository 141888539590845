import firebase from "../../firebase";

export default function({ officeId, meaningButtonIds }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "pagingAdmin",
        funcName: "reorderMeaningButtons",
        data: {
            officeId,
            meaningButtonIds
        }
    });
}
