import { useEffect, useContext } from "react";
import useColumnLayoutLocalState from "./useColumnLayoutLocalState";
import { PatientTrackingContext } from "../PatientTrackingContext";
import firebase from "../../../../../firebase";
import { OfficeContext } from "../../OfficeContext";
const defaultColumnLayout = {
    Name: { order: 1, name: "Name", id: "Name", enabled: true },
    Room: { id: "Room", enabled: true, order: 2, name: "Room" },
    Stage: { id: "Stage", enabled: true, order: 3, name: "Stage" },
    ArrivalTime: {
        order: 4,
        name: "Arrival Time",
        id: "ArrivalTime",
        enabled: true
    },
    AppointmentTime: {
        order: 5,
        name: "Appointment Time",
        id: "AppointmentTime",
        enabled: true
    },
    TimeElapsed: {
        id: "TimeElapsed",
        enabled: true,
        order: 6,
        name: "Time Elapsed"
    },
    TimeRemaining: {
        order: 7,
        name: "Time Remaining",
        id: "TimeRemaining",
        enabled: true
    },
    TimeOnStage: {
        order: 8,
        name: "Time on Stage",
        id: "TimeOnStage",
        enabled: true
    }
};

export default function() {
    const patientTracking = useContext(PatientTrackingContext);
    const { officeId } = useContext(OfficeContext);
    const localState = useColumnLayoutLocalState();

    useEffect(() => {
        localState.setTrackerColumns(defaultColumnLayout);
        localState.setModified(false);
    }, []);

    useEffect(() => {

        if (
            patientTracking.columnLayout &&
            Object.keys(patientTracking.columnLayout).length > 0
        ) {

            localState.setTrackerColumns(patientTracking.columnLayout);
  
            localState.setModified(false);
        }
    }, [patientTracking.columnLayout]);

    function updateColumnLayout() {
        const trackerColumns = Object.keys(localState.trackerColumns).map(
            columnId => {
                return localState.trackerColumns[columnId];
            }
        );

        const req = {
            funcName: "updateColumnLayout",
            funcRole: "patientTrackingAdmin",
            data: { officeId, layout: trackerColumns }
        }
    
        firebase.functions().httpsCallable("allFunctions")(req).then(()=>{
            patientTracking.getPatientTracking()
        })

    }

    return { localState, updateColumnLayout };
}
