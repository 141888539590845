import React, { useState, useContext } from 'react';
import moment from "moment";
import MomentUtils from '@date-io/moment';

import { makeStyles, CircularProgress } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import VisitReportContext from "./VisitReportContext";
import CustomButton from '../../../CustomButton';

const useStyles = makeStyles({
    customPopover: {
        '& .MuiPickersToolbar-toolbar': {
            background: 'linear-gradient(180deg, #B5E346 0%, #9FCF2C 100%)',
        },
        '& .MuiPickersBasePicker-pickerView': {
            '& .MuiPickersMonth-monthSelected': {
                color: '#B5E346',
            },
            '& .MuiPickersYear-yearSelected': {
                color: '#B5E346',
            },
            '& .MuiPickersDay-daySelected': {
                backgroundColor: '#B5E346',
            },
            '& .MuiButton-textPrimary': {
                color: '#B5E346',
            }
        },
        '& .MuiDialogActions-root': {
            '& .MuiButton-textPrimary': {
                color: '#B5E346',
            }
        },
    },
    datePicker: {
        width: '100%',
        marginRight: 10,
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ECEEF2',
        },
        '& input': {
            padding: 16,
            boxShadow: 'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',

        }
    }
});

function Controls() {
    const classes = useStyles();
    const reportCtx = useContext(VisitReportContext);

    const [startDate, setStartDate] = useState(moment("0", "h").unix() * 1000);
    const [endDate, setEndDate] = useState(
        moment("0", "h")
            .add(1, "d")
            .unix() * 1000
    );

    return (
        <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ width: '45%' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        maxDate={new Date()}
                        inputVariant="outlined"
                        focused={false}
                        DialogProps={{ className: classes.customPopover }}
                        className={classes.datePicker}
                        value={startDate}
                        onChange={e => {
                            const timestamp = e.unix() * 1000;
                            setStartDate(timestamp);
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div style={{ marginLeft: 15, marginRight: 15, width: '45%' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        maxDate={new Date()}
                        inputVariant="outlined"
                        focused={false}
                        error={false}
                        helperText=''
                        DialogProps={{ className: classes.customPopover }}
                        className={classes.datePicker}
                        value={endDate}
                        onChange={e => {
                            const timestamp = e.unix() * 1000;
                            setEndDate(timestamp);
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
            {reportCtx.fetching ?
                <div style={{ display: 'flex', justifyContent: 'center', minWidth: 110 }}>
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                <CustomButton
                    label='GET'
                    onClick={() => {
                        reportCtx.getReport(startDate, endDate);
                    }}
                />
            }
        </div>

    );
}

export default Controls;
