import firebase from "../../firebase";

export default function({ officeId, stageIds }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingAdmin",
        funcName: "reorderStages",
        data: {
            officeId,
            stageIds
        }
    });
}
