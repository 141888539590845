import firebase from "../../firebase";

export default function({ officeId, planId, planQuantity }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "billingAdmin",
        funcName: "createSubscription",
        data: {
            officeId: officeId,
            plan_id: planId,
            plan_quantity: planQuantity
        }
    });
}
