import React, { useContext, useState, useEffect } from 'react';

import { makeStyles, IconButton, Divider, CircularProgress, Modal } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CreateRounded from '@material-ui/icons/CreateRounded';
import DeleteRounded from '@material-ui/icons/DeleteRounded';

import { PagingContext } from "./PagingContext";
import { OfficeContext } from "../OfficeContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { appContext } from "../../../../contexts/app";
import { reorderRooms } from "../../../../insyncHttps/paging/paging";

import CustomTextField from '../../../CustomTextField';
import CustomButton from '../../../CustomButton';

import AddRoom from './AddRoom';
import { IcArrowUp, IcArrowDown } from '../../../IconSVG';

const useStyles = makeStyles({
    infoContainer: {
        borderRadius: 4,
        paddingTop: 32,
        paddingBottom: 76,
        paddingLeft: 31,
        paddingRight: 26,
        background: 'white',
        border: '1px solid #EDEEF0',
        marginTop: 5,
        fontFamily: 'SF Pro Display'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        marginLeft: 10
    },
    dataRoom: {
        height: 65,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: '1px solid #EDEEF0',
        paddingLeft: 15,
        paddingRight: 15,
    }
});

function Rooms() {
    const classes = useStyles();
    const { rooms, loading, getPaging } = useContext(PagingContext);
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);

    const [nameRoom, setNameRoom] = useState(null);
    const [editRoom, setEditRoom] = useState(null);
    const [deleteRoom, setDeleteRoom] = useState(null);
    const [errorField, setErrorField] = useState(false);
    const [addRoomOpen, setAddRoomOpen] = useState(false);
    const [loadingP, setLoadingP] = useState(false);
    const [orderedRoomIds, setOrderedRoomIds] = useState([]);
    const [orderModified, setOrderModified] = useState(false);
    const [saveOrderLoading, setSaveOrderLoading] = useState(false);

    const subminNewNameRoom = (item) => () => {
        setLoadingP(true);
        const roomId = item.roomId
        if (nameRoom === '' || nameRoom === undefined) {
            setErrorField(true)
            setLoadingP(false)
        } else {
            insyncHttps.paging
                .updateRoom({ officeId, roomId, name: nameRoom })
                .then(result => {
                    setLoadingP(false);
                    getPaging();
                    setEditRoom(null)
                })
                .catch(err => {
                    console.error(err);
                    setLoadingP(false);
                    setEditRoom(null)
                    error.setMessage(err.message);
                });
        }
    }

    const submindeleteRoom = (item) => () => {
        const roomId = item.roomId
        setLoadingP(true);
        insyncHttps.paging
            .removeRoomFromOffice({ officeId, roomId })
            .then(result => {
                setLoadingP(false);
                getPaging();
                setDeleteRoom(null)
            })
            .catch(err => {
                setLoadingP(false);
                error.setMessage(err.message);
                setDeleteRoom(null)
            });
    }

    const changeOrder = () => {
        setSaveOrderLoading(true);
        reorderRooms({
            officeId,
            roomIds: orderedRoomIds
        })
            .then(result => {
                setSaveOrderLoading(false);
                getPaging();
            })
            .catch(err => {
                setSaveOrderLoading(false);
                getPaging();
            });
    }

    function moveRoomUp(roomId) {
        const currentIndex = orderedRoomIds.indexOf(roomId);
        if (currentIndex > 0) {
            orderedRoomIds.splice(currentIndex, 1);
            orderedRoomIds.splice(currentIndex - 1, 0, roomId);
            setOrderModified(true);
            setOrderedRoomIds([...orderedRoomIds]);
        }
    }

    function moveRoomDown(roomId) {
        const currentIndex = orderedRoomIds.indexOf(roomId);
        if (currentIndex < orderedRoomIds.length - 1) {
            orderedRoomIds.splice(currentIndex, 1);
            orderedRoomIds.splice(currentIndex + 1, 0, roomId);
            setOrderedRoomIds([...orderedRoomIds]);
            setOrderModified(true);
        }
    }

    useEffect(() => {
        setOrderModified(false);
        setOrderedRoomIds(getSortedRoomIds(rooms));
    }, [rooms]);

    return (
        <div className={classes.infoContainer}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 16
                }}
            >
                <p className={classes.title}>
                    Rooms
                </p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 10 }}>
                        {saveOrderLoading ?
                            <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                            :
                            orderModified && (
                                <div style={{ minWidth: 130 }}>
                                    <CustomButton
                                        width='full'
                                        label='save'
                                        onClick={changeOrder}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <IconButton
                        style={{ background: 'rgba(157, 162, 180, 0.08)', padding: 5, height: 40, width: 40 }}
                        onClick={() => {
                            setAddRoomOpen(true);
                        }}
                    >
                        <AddRoundedIcon
                            style={{ color: '#9DA2B4' }}
                            fontSize='medium'
                        />
                    </IconButton>
                </div>
                <Modal
                    style={{ display: "flex" }}
                    open={addRoomOpen}
                    disableAutoFocus={true}
                >
                    <AddRoom
                        handleClose={() => {
                            setAddRoomOpen(false);
                        }}
                    />
                </Modal>
            </div>
            {loading ?
                <div
                    style={{
                        display: 'flex',
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                orderedRoomIds.map(roomId => {
                    const room = rooms[roomId];
                    return (
                        <div key={roomId}>
                            {(deleteRoom && deleteRoom.roomId === roomId) || (editRoom && editRoom.roomId === roomId) ?
                                <div
                                    className={classes.dataRoom}
                                    style={{ background: deleteRoom ? 'rgba(240, 100, 34, 0.12)' : 'white' }}
                                >
                                    <div style={{ width: deleteRoom ? '60%' : '80%', display: 'flex', alignItems: 'center' }}>
                                        {deleteRoom ?
                                            <div>
                                                <p style={{ margin: 0, color: "#EC5829" }}>
                                                    {room.name}
                                                </p>
                                            </div>
                                            :
                                            <CustomTextField
                                                value={nameRoom}
                                                placeholder='Room name'
                                                onChange={e => {
                                                    setNameRoom(e.target.value);
                                                }}
                                                error={nameRoom === '' ? errorField : false}
                                            />
                                        }
                                    </div>
                                    {loadingP ?
                                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                                        :
                                        <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: deleteRoom ? 193 : 127 }}>
                                                <CustomButton
                                                    width='full'
                                                    label={deleteRoom ? 'Delete' : 'Save'}
                                                    backgroundred={deleteRoom ? '#F86F46' : ''}
                                                    onClick={
                                                        deleteRoom ?
                                                            submindeleteRoom({ ...room, roomId })
                                                            :
                                                            subminNewNameRoom({ ...room, roomId })
                                                    }
                                                />
                                            </div>
                                            <span
                                                className={classes.subTitle}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setEditRoom(null)
                                                    setDeleteRoom(null)
                                                }}
                                            >
                                                Cancel
                                            </span>
                                        </div>
                                    }
                                </div>
                                :
                                <div className={classes.dataRoom}>
                                    <p>{room && room.name}</p>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <IconButton
                                            style={{ height: 40, width: 40 }}
                                            onClick={() => {
                                                moveRoomUp(roomId);
                                            }}
                                        >
                                            <IcArrowUp
                                                width='7'
                                                height='15'
                                                fill='#9DA2B4'
                                            />
                                        </IconButton>
                                        <IconButton
                                            style={{ height: 40, width: 40 }}
                                            onClick={() => {
                                                moveRoomDown(roomId);
                                            }}
                                        >
                                            <IcArrowDown
                                                width='7'
                                                height='15'
                                                fill='#9DA2B4'
                                            />
                                        </IconButton>
                                        <div style={{ width: 2, height: 33, backgroundColor: '#EDEEF0' }} />
                                        <IconButton
                                            style={{ height: 40, width: 40 }}
                                            onClick={() => {
                                                setEditRoom({ ...room, roomId });
                                                setNameRoom(room.name);
                                                setDeleteRoom(null);
                                                setLoadingP(false)
                                            }}
                                        >
                                            <CreateRounded style={{ color: '#9DA2B4' }} />
                                        </IconButton>
                                        <IconButton
                                            style={{ height: 40, width: 40 }}
                                            onClick={() => {
                                                setDeleteRoom({ ...room, roomId });
                                                setEditRoom(null);
                                                setLoadingP(false)
                                            }}
                                        >
                                            <DeleteRounded style={{ color: '#D8DAE1' }} />
                                        </IconButton>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })
            }
            <Divider style={{ backgroundColor: '#EDEEF0' }} />
        </div>
    );
}

export default Rooms;

function getSortedRoomIds(rooms) {
    return Object.keys(rooms)
        .filter(roomId => {
            return Object.keys(rooms).includes(roomId);
        })
        .sort((a, b) => {
            if (rooms[a].name > rooms[b].name) {
                return 1;
            }
            if (rooms[a].name < rooms[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const roomA = { ...rooms[a] };
            const roomB = { ...rooms[b] };
            roomA.listPosition = roomA.listPosition ? roomA.listPosition : 0;
            roomB.listPosition = roomB.listPosition ? roomB.listPosition : 0;

            return roomA.listPosition - roomB.listPosition;
        });
}