import React, { useContext } from "react";
import ColumnLayoutContext from "./ColumnLayoutContext";
import {
    IconButton,
    makeStyles
} from "@material-ui/core";
import CustomCheckBox from '../../../../CustomCheckBox';
import { IcArrowUp, IcArrowDown } from '../../../../IconSVG';

const baseStyle = {
    color: '#197FE8',
    fontWeigh: 500
};
const disabledStyle = {
    color: "#979797",
    fontWeigh: 400
};

const useStyles = makeStyles({
    itemCustom: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

export default function (props) {
    const classes = useStyles();
    const { columnId } = props;
    const { localState } = useContext(ColumnLayoutContext);
    const column = localState.trackerColumns[columnId];

    const itemStyle = (() => {
        return column.enabled ? baseStyle : { ...baseStyle, ...disabledStyle };
    })();

    return (

        <div style={itemStyle} className={classes.itemCustom}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <CustomCheckBox
                    checked={column.enabled}
                    onChange={(e, v) => {
                        localState.setColumnEnabled(columnId, v);
                    }}
                    disabled={column.name === 'Name' ? true : false}
                />
                <p style={{ margin: 10 }}>
                    {column.name === 'Time Elapsed' ? 'Total' : column.name === 'Time on Stage' ? 'Stage' : column.name}
                </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    style={{ height: 40, width: 40 }}
                    onClick={() => {
                        localState.moveColumnUp(columnId);
                    }}
                >
                    <IcArrowUp width='7' height='15' fill='#9DA2B4' />
                </IconButton>
                <IconButton
                    style={{ height: 40, width: 40 }}
                    onClick={() => {
                        localState.moveColumnDown(columnId);
                    }}
                >
                    <IcArrowDown width='7' height='15' fill='#9DA2B4' />
                </IconButton>
            </div>
        </div>
    );
}
