import firebase from "../../firebase";

export default function ({
    officeId,
    fullName,
    cardNumber,
    cvv,
    expiry_month,
    expiry_year,
}) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "billingAdmin",
        funcName: "createPaymentSource",
        data: {
            officeId,
            fullName,
            number: cardNumber,
            cvv,
            expiry_month,
            expiry_year,
        }
    });
}
