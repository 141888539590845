import firebase from "../../firebase";

export default function({ officeId, name, passwordProtected, password, bgColor, lastName, initials, phoneNumber, soundId }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "messagingAdmin",
        funcName: "addUser",
        data: {
            officeId,
            name,
            lastName,
            passwordProtected,
            password,
            bgColor,
            initials,
            phoneNumber,
            soundId
        }
    });
}
