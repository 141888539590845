import React, { useContext, useEffect, useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";

import { authContext } from "../../../contexts/auth";
import { mainRouterHistory } from "../../../routerHistories";
import ErrorStateDisplay from "../../ErrorStateDisplay/ErrorStateDisplay";
import { DashboardContext, useDashboard } from "./DashboardContext";
import insyncHttps from "../../../insyncHttps/insyncHttps";

import OfficeList from "./OfficeList";
import CustomTextField from '../../CustomTextField';
import CustomButton from '../../CustomButton';
import CustomNavBar from "../../CustomNavBar";

function Dashboard() {
    const { user } = useContext(authContext);
    const auth = useContext(authContext);
    const { officesMeta, getOfficeList, error, loading } = useDashboard();
    const [name, setName] = useState("");
    const [loadingSend, setLoadingSend] = useState(false);

    const ListNotEmpty = Object.keys(officesMeta).length !== 0

    useEffect(() => {
        if (!user) {
            mainRouterHistory.push("/");
        } else {
            getOfficeList();
        }
    }, []);

    const subminNewOffice = () => {
        setLoadingSend(true);
        insyncHttps.office
            .createOffice({ name })
            .then(async result => {
                await auth.reload();
                setLoadingSend(false);
                getOfficeList();
            })
            .catch(err => {
                setLoadingSend(false);
            });

    }

    return (
        <DashboardContext.Provider
            value={{ officesMeta, getOfficeList, error, loading }}
        >
            <CustomNavBar />
            <Grid
                container
                style={{ marginTop: 30, background: '#F8F9FB' }}
                justifyContent='center'
            >
                <Grid
                    item
                    xs={11} sm={7} md={5} lg={4} xl={3}
                    style={{ marginTop: 15 }}
                >
                    <div style={{ display: 'flex', justifyContent: ListNotEmpty ? 'center' : 'left' }}>
                        <p
                            style={{
                                fontSize: 24,
                                fontWeight: 'bold',
                                marginBottom: 20,
                                fontFamily: 'SF Pro Display'
                            }}>
                            Offices
                        </p>
                    </div>
                    {loading ?
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                        </div> :
                        ListNotEmpty ?
                            error ? (
                                <ErrorStateDisplay
                                    title="Error Getting Offices"
                                    message={error}
                                    retry={getOfficeList}
                                />
                            ) : (
                                <OfficeList />
                            )
                            :
                            <div>
                                <div style={{ marginBottom: 20 }}>
                                    <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left' }}>
                                        Office name
                                    </p>
                                    <CustomTextField
                                        value={name}
                                        placeholder="Add a name for your office"
                                        onChange={e => {
                                            setName(e.target.value);
                                        }}
                                    />
                                    <div style={{ marginTop: 17 }}>
                                        {loadingSend ?
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                                            </div>
                                            :
                                            <CustomButton
                                                label='Create Your first Office'
                                                onClick={subminNewOffice}
                                                width='full'
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                    }



                </Grid>
            </Grid>
        </DashboardContext.Provider>
    );
}

export default Dashboard;