export default {
    sine: {
        oscillators: [{ frequencyRatio: 1, volume: 1 }],

        volumeEnvelope: {
            attack: 0.01,
            decay: 0,
            sustain: 1,
            release: 0.1
        }
    },
    st1: {
        oscillators: [
            { frequencyRatio: 1, volume: 0.8 },
            { frequencyRatio: 2, volume: 0.4 }
        ],

        volumeEnvelope: {
            attack: 0.01,
            decay: 0,
            sustain: 1,
            release: 0.05
        }
    },
    st2: {
        oscillators: (() => {
            let oscs = [];
            for (let i = 1; i < 10; i++) {
                if (i % 2 !== 0) {
                    oscs.push({ frequencyRatio: i + i * 0.01, volume: (1/2) / i });
                } else {
                    oscs.push({
                        frequencyRatio: i + i * 0.01,
                        volume: 1 / (i * 2)
                    });
                }
            }
            return oscs;
        })(),

        volumeEnvelope: {
            attack: 0.01,
            decay: 0,
            sustain: 1,
            release: 0.1
        }
    },
    st3: {
        oscillators: (() => {
            let oscs = [];
            for (let i = 1; i < 10; i++) {
                if (i % 2 === 0) {
                    oscs.push({ frequencyRatio: i, volume: (1/2) / i });
                }
            }
            return oscs;
        })(),

        volumeEnvelope: {
            attack: 0.0,
            decay: 0,
            sustain: 1,
            release: 0.05
        }
    },
    st4: {
        oscillators: (() => {
            let oscs = [];
            for (let i = 1; i < 20; i++) {
                if (i % 2 === 0) {
                    oscs.push({ frequencyRatio: i, volume: (1/2) / i });
                }
            }
            return oscs;
        })(),

        volumeEnvelope: {
            attack: 0.02,
            decay: 0,
            sustain: 1,
            release: 0.1
        }
    }
};
