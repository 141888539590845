import firebase from "../../firebase";

export default function({ officeId, meaningButtonId, iconId, name }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "pagingAdmin",
        funcName: "updateMeaningButton",
        data: {
            officeId,
            meaningButtonId,
            iconId,
            name
        }
    });
}
