
import React, { useState, useContext, useEffect } from 'react';
import { VictoryPie } from "victory";

import { makeStyles, Divider } from '@material-ui/core';

import VisitReportContext from "./VisitReportContext";

const useStyles = makeStyles({
    infoContainer: {
        borderRadius: 4,
        paddingTop: 21,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 26,
        background: 'white',
        border: '1px solid #EDEEF0',
        marginTop: 15,
        fontFamily: 'SF Pro Display',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontSize: 16,
        fontWeight: 500,
        margin: 0,
        marginBottom: 10
    },
});


function ReportSecundContainer() {
    const classes = useStyles();
    const reportCtx = useContext(VisitReportContext);
    const [slices, setSlices] = useState([]);

    useEffect(() => {
        setSlices(createSlices());
    }, [reportCtx.visits]);

    function getStageById(id) {
        return reportCtx.stages.find(stage => {
            return stage.id === id;
        });
    }

    function createSlices() {
        //get cumulative total of time spent in each stage
        const combinedStageTimes = {};
        reportCtx.visits.filter(visit => { return visit.completedTime && visit.completedTime > 0 }).forEach(visit => {
            Object.keys(visit.stageTimes).forEach(stageId => {
                if (!combinedStageTimes[stageId]) {
                    combinedStageTimes[stageId] = {
                        id: stageId,
                        totalTime: visit.stageTimes[stageId]
                    };
                } else {
                    combinedStageTimes[stageId].totalTime +=
                        visit.stageTimes[stageId];
                }
            });
        });

        const sliceData = Object.keys(combinedStageTimes).map(
            combinedStageTimesId => {
                const element = combinedStageTimes[combinedStageTimesId];
                const stage = getStageById(element.id);
                element.name = stage && stage.name ? stage.name : element.id;
                element.backgroundColor =
                    stage && stage.backgroundColor
                        ? stage.backgroundColor
                        : "grey";
                element.textColor =
                    stage && stage.textColor ? stage.textColor : "black";
                element.value = element.totalTime;
                return element;
            }
        );

        const sumOfTime = sliceData.reduce((acc, curr) => {
            return acc + curr.totalTime;
        }, 0);

        sliceData.forEach(element => {
            element.percent = Math.round((element.totalTime / sumOfTime) * 100);
        });

        return sliceData;
    }

    return (
        <div className={classes.infoContainer}>
            <p className={classes.title}>
                Average Percent of visit spent in stage
            </p>
            <Divider style={{ backgroundColor: '#EDEEF0' }} />
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ maxWidth: 300, maxHeight: 260, display: 'flex', alignItems: 'center' }}>
                    <VictoryPie
                        colorScale={(() => {
                            return slices.map(slice => {
                                return slice.backgroundColor;
                            });
                        })()}
                        data={slices.map(slice => {
                            // return { ...slice, y: slice.value };
                            return { ...slice, y: slice.value };
                        })}
                        animate={{
                            duration: 2000
                        }}
                        events={[
                            {
                                target: "data",
                                eventHandlers: {
                                    onClick: (evt, props) => {
                                        const { datum } = props;
                                        alert(datum.name);
                                    }
                                }
                            }
                        ]}
                        height={300}
                        width={300}
                        style={{
                            labels: { fontSize: 0 },
                            data: { stroke: "#FFFF", strokeWidth: 2 }
                        }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }} >
                    <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                        Total Allocation
                    </p>
                    <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: 450 }}>
                        {slices.map(e => {
                            return (
                                <div
                                    style={{ display: 'flex', alignItems: 'center', margin: 10, width: 200 }}
                                    key={e.name}
                                >
                                    <div style={{
                                        height: 32,
                                        width: 32,
                                        background: e.backgroundColor,
                                        borderRadius: 32,
                                        minWidth: 32,
                                    }}
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                        <p style={{ margin: 0, color: '#828282', fontSize: 14 }}>
                                            {e.percent}%
                                        </p>
                                        <p style={{ margin: 0, wordBreak: 'break-all' }}>
                                            {e.name}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportSecundContainer;
