import { createContext, useState } from "react";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export const UsersContext = createContext({
    users: { users: [], loading: false, getUsers: () => {} }
});

export const useUsers = officeId => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const getUsers = () => {
        setLoading(true);
        insyncHttps.insyncUser
            .getUsersForOffice({ officeId })
            .then(result => {
                setLoading(false);
                setUsers(result.data);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };

    return { users, loading, getUsers };
};
