
import React, { useState, useContext, useEffect } from 'react';
import { VictoryBar, VictoryChart } from "victory";

import { makeStyles, Divider } from '@material-ui/core';

import VisitReportContext from "./VisitReportContext";

const useStyles = makeStyles({
    infoContainer: {
        borderRadius: 4,
        paddingTop: 21,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 26,
        background: 'white',
        border: '1px solid #EDEEF0',
        marginTop: 15,
        fontFamily: 'SF Pro Display',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontSize: 16,
        fontWeight: 500,
        margin: 0,
        marginBottom: 10
    },
    barCustom: {
        '& svg': {
            height: '300px !important'
        }
    }
});

function ReportThirdContainer() {
    const classes = useStyles();
    const reportCtx = useContext(VisitReportContext);
    const [slices, setSlices] = useState([{ totalTime: 1 }]);

    useEffect(() => {
        const mySlices = createSlices();
        setSlices(mySlices.length > 0 ? mySlices : [{ totalTime: 0 }]);
    }, [reportCtx.visits]);

    function getStageById(id) {
        return reportCtx.stages.find(stage => {
            return stage.id === id;
        });
    }

    function createSlices() {
        //get cumulative total of time spent in each stage
        const combinedStageTimes = {};
        reportCtx.visits
            .filter(visit => {
                return visit.completedTime && visit.completedTime > 0;
            })
            .forEach(visit => {
                Object.keys(visit.stageTimes).forEach(stageId => {
                    if (!combinedStageTimes[stageId]) {
                        combinedStageTimes[stageId] = {
                            id: stageId,
                            totalTime: visit.stageTimes[stageId]
                        };
                    } else {
                        combinedStageTimes[stageId].totalTime +=
                            visit.stageTimes[stageId];
                    }
                });
            });

        const sliceData = Object.keys(combinedStageTimes).map(
            combinedStageTimesId => {
                const element = combinedStageTimes[combinedStageTimesId];
                const stage = getStageById(element.id);
                element.name = stage && stage.name ? stage.name : element.id;
                element.backgroundColor =
                    stage && stage.backgroundColor
                        ? stage.backgroundColor
                        : "grey";
                element.textColor =
                    stage && stage.textColor ? stage.textColor : "black";
                element.value = element.totalTime;
                return element;
            }
        );

        return sliceData;
    }

    return (
        <div className={classes.infoContainer}>
            <p className={classes.title}>
                Average time in stage per visit
            </p>
            <Divider style={{ backgroundColor: '#EDEEF0' }} />
            <div className={classes.barCustom}>
                <VictoryChart domainPadding={{ x: 15 }}>
                    <VictoryBar
                        events={[
                            {
                                target: "data", eventHandlers: {
                                    onClick: (evt, props) => {
                                        const { datum } = props;
                                        alert(datum.name);
                                    }
                                }
                            }
                        ]}
                        style={{
                            data: {
                                fill: data => {
                                    return data.datum.backgroundColor;
                                }
                            },
                            labels: {
                                fontSize: 15, fill: data => {
                                    return data.datum.backgroundColor;
                                }
                            },
                        }}
                        animate={{ duration: 2000 }}
                        labels={({ datum }) => {
                            return `${Math.floor(datum.totalTime / 60000)} Minutes`;
                        }}
                        horizontal={true}
                        barWidth={20}
                        data={slices && slices.length > 0 && slices.map((slice) => {
                            return {
                                ...slice,
                                y: Math.floor(slice.totalTime / 60000),
                                x: slice && slice.name
                            };
                        })}
                    />
                </VictoryChart>
            </div>
        </div>
    );
}

export default ReportThirdContainer;
