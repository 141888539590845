import React, { useContext, useState } from "react";
import {
    makeStyles,
    Divider,
    CircularProgress
} from "@material-ui/core";

import { PatientTrackingContext } from "../PatientTrackingContext";
import { appContext } from "../../../../../contexts/app";

import CustomButton from '../../../../CustomButton';

import ColumnListContext from "./ColumnLayoutContext";
import useColumnLayout from "./useColumnLayout";
import ColumnList from './ColumnList';

const useStyles = makeStyles({
    infoContainer: {
        background: 'white',
        borderRadius: 4,
        marginTop: 21,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'SF Pro Display',
        border: '1px solid #EDEEF0',
        padding: 30,
        height:'fit-content'
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
    },
});

function ColumnLayout() {
    const classes = useStyles();
    const columnLayoutCtx = useColumnLayout();
    const patientTracking = useContext(PatientTrackingContext);
    const [buttonAction, setButtonAction] = useState(false);
    const { error } = useContext(appContext);


    const handleSave = () => {
        const resul = Object.keys(columnLayoutCtx.localState.trackerColumns).filter((x) => columnLayoutCtx.localState.trackerColumns[x].enabled === true).length;
        if (resul > 5) {
            setButtonAction(true)
            error.setMessage('you can only have 5 columns enabled');
        } else {
            setButtonAction(false)
            columnLayoutCtx.updateColumnLayout();
        }
    }
    

    return (
        <ColumnListContext.Provider value={columnLayoutCtx}>

            <div className={classes.infoContainer}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p className={classes.title}>
                        Column Layout
                    </p>
                    {columnLayoutCtx.localState.modified && (
                        <CustomButton
                            onClick={handleSave}
                            label='update'
                        />
                    )}
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0', marginTop: 15 }} />
                {patientTracking.loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <ColumnList/>
                    
                }
            </div>
        </ColumnListContext.Provider>
    );
}

export default ColumnLayout;