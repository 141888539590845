
import React, { useContext, useEffect } from 'react';

import { makeStyles, Divider } from '@material-ui/core';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

import VisitReportContext from "./VisitReportContext";

const useStyles = makeStyles({
    infoContainer: {
        borderRadius: 4,
        paddingTop: 21,
        paddingBottom: 25,
        paddingLeft: 30,
        paddingRight: 26,
        background: 'white',
        border: '1px solid #EDEEF0',
        fontFamily: 'SF Pro Display',
        width: '42%',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontSize: 16,
        fontWeight: 500,
        margin: 0,
        marginBottom: 10
    },
    detail: {
        color: '#197FE8',
        fontWeight: 600,
        fontSize: 24,
        margin: 0,
        marginLeft: 6
    }
});

function ReportFirstConainer() {
    const classes = useStyles();
    const reportCtx = useContext(VisitReportContext);
    const { visits } = reportCtx;

    useEffect(() => { }, [reportCtx.visits]);

    const totalVisits = reportCtx.visits.filter(visit => {
        return visit.completedTime && visit.completedTime > 0;
    }).length;

    const averageVisitTime = (() => {
        const completedVisits = reportCtx.visits.filter(visit => {
            return visit.completedTime && visit.completedTime > 0;
        });

        const totalTime = completedVisits.reduce((acc, curr) => {
            return (acc += curr.completedTime - curr.arrivalTime);
        }, 0);

        return Math.floor(totalTime / completedVisits.length / 60000) || 0;
    })();

    function getLongestVisit() {
        const completedVisits = visits.filter(visit => {
            return visit.completedTime && visit.completedTime > 0;
        });

        const sortedVisits = completedVisits.sort((a, b) => {
            const aduration = a.completedTime - a.arrivalTime;
            const bduration = b.completedTime - b.arrivalTime;
            return aduration - bduration;
        });

        if (sortedVisits.length > 0) {
            return sortedVisits[sortedVisits.length - 1];
        } else {
            return null;
        }
    }

    function getShortestVisit() {
        const completedVisits = visits.filter(visit => {
            return visit.completedTime && visit.completedTime > 0;
        });

        const sortedVisits = completedVisits.sort((a, b) => {
            const aduration = a.completedTime - a.arrivalTime;
            const bduration = b.completedTime - b.arrivalTime;
            return aduration - bduration;
        });

        if (sortedVisits.length > 0) {
            return sortedVisits[0];
        } else {
            return null;
        }
    }

    const visit = getLongestVisit();
    const shortestVisit = getShortestVisit();

    const visitTime =
        visit && visit.completedTime && visit.arrivalTime
            ? Math.floor((visit.completedTime - visit.arrivalTime) / 60000)
            : 0;

    const visitShortestTime =
        shortestVisit && shortestVisit.completedTime && shortestVisit.arrivalTime
            ? Math.floor((shortestVisit.completedTime - shortestVisit.arrivalTime) / 60000)
            : 0;


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={classes.infoContainer}>
                    <p className={classes.title}>
                        Visits
                    </p>
                    <Divider style={{ backgroundColor: '#EDEEF0' }} />
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <PersonRoundedIcon style={{ color: '#9DA2B4' }} />
                        <p className={classes.detail}>
                            {totalVisits}
                        </p>
                    </div>
                </div>
                <div className={classes.infoContainer}>
                    <p className={classes.title}>
                        Average Visit Time
                    </p>
                    <Divider style={{ backgroundColor: '#EDEEF0' }} />
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <img src='/assets/timeIcon.png' alt='time' />
                        <p className={classes.detail}>
                            {averageVisitTime} minutes
                        </p>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15    }}>
                <div className={classes.infoContainer}>
                    <p className={classes.title}>
                        Longest Visit
                    </p>
                    <Divider style={{ backgroundColor: '#EDEEF0' }} />
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <PersonRoundedIcon style={{ color: '#9DA2B4' }} />
                        <p className={classes.detail}>
                            {visitTime}
                        </p>
                    </div>
                </div>
                <div className={classes.infoContainer}>
                    <p className={classes.title}>
                        Shortest Visit
                    </p>
                    <Divider style={{ backgroundColor: '#EDEEF0' }} />
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <PersonRoundedIcon style={{ color: '#9DA2B4' }} />
                        <p className={classes.detail}>
                            {visitShortestTime}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportFirstConainer;
