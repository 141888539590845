import firebase from "../../firebase";

export default function({ officeId, meaningButtonId }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "pagingAdmin",
        funcName: "removeMeaningButtonFromOffice",
        data: {
            officeId,
            meaningButtonId
        }
    });
}
