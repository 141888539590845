import React, { useState, forwardRef } from "react";
import {
    Card,
    CardContent,
    CardActions,
    Divider,
    CircularProgress,
    makeStyles,
} from "@material-ui/core";

import CustomTextField from "../../../../CustomTextField";
import CustomButton from "../../../../CustomButton";

const useStyles = makeStyles({
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        fontFamily: 'SF Pro Display',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
    },
    subTitle: {
        fontSize: 17,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
    },
});

const ChangeEmail = forwardRef((props, ref) => {
    const { handleClose, nameOffice, subminEmail, loading, errorField } = props;
    const classes = useStyles();

    const [nameOfficeUp, setNameOfficeUp] = useState(nameOffice);


    return (
        <Card ref={ref} className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 23 }} className={classes.title}>
                        Update office
                    </p>
                    <p
                        style={{ marginBottom: 23, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }}/>
                <div style={{ marginBottom: 46, paddingLeft: 25, paddingRight: 25, fontFamily: 'SF Pro Display' }}>
                    <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 30 }}>
                        Office name
                    </p>
                    <CustomTextField
                        value={nameOfficeUp}
                        placeholder="Add a name for your office"
                        error={nameOfficeUp === '' ? errorField : false}
                        onChange={e => {
                            setNameOfficeUp(e.target.value);
                        }}
                    />
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }}/>
            </CardContent>
            <CardActions style={{ marginTop: 32, paddingLeft: 25, paddingRight: 25 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        width='full'
                        label='submit'
                        onClick={subminEmail(nameOfficeUp)}
                    />
                }
            </CardActions>
        </Card>
    );
});
export default ChangeEmail