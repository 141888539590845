import React from 'react';

const ArrowUp = (props) => {
    const { width, height, fill } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.49299 15H3.49299C3.21685 15 2.99299 14.7761 2.99299 14.4998V3.99507H0.99299C0.792953 3.99226 0.613846 3.87044 0.537674 3.6854C0.461503 
            3.50035 0.502931 3.2877 0.64299 3.14481L3.64299 0.143882C3.8408 -0.0479606 4.15518 -0.0479606 4.35299 0.143882L7.35299 3.14481C7.49429 3.289 7.53506 
            3.50405 7.45634 3.68998C7.37761 3.87591 7.19484 3.99625 6.99299 3.99507H4.99299V14.4998C4.99299 14.7761 4.76913 15 4.49299 15Z" fill={fill}
            />

        </svg>

    )
}
export default ArrowUp;