import React, { useContext, useState, useEffect } from 'react';

import {
    makeStyles,
    IconButton,
    Divider,
    CircularProgress,
    Dialog
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CreateRounded from '@material-ui/icons/CreateRounded';
import DeleteRounded from '@material-ui/icons/DeleteRounded';

import { PagingContext } from "./PagingContext";
import { OfficeContext } from "../OfficeContext";
import { appContext } from "../../../../contexts/app";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { reorderMeaningButtons } from "../../../../insyncHttps/paging/paging";

import MeaningIcons from './MeaningIcons';
import AddMeaningButtons from './AddMeaningButtons';
import EditMeaningButton from './EditMeaningButton';
import CustomButton from '../../../CustomButton';
import { IcArrowUp, IcArrowDown } from '../../../IconSVG';

const useStyles = makeStyles({
    infoContainer: {
        borderRadius: 4,
        paddingTop: 32,
        paddingBottom: 76,
        paddingLeft: 31,
        paddingRight: 26,
        background: 'white',
        border: '1px solid #EDEEF0',
        marginTop: 5,
        fontFamily: 'SF Pro Display'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        marginLeft: 10
    },
    dataRoom: {
        height: 65,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: '1px solid #EDEEF0',
        paddingLeft: 15,
        paddingRight: 15,
    },
    customDialog: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
        },
        '& .MuiPaper-elevation24': {
            boxShadow: 'none'
        }
    }
});

function MeaningButtons() {
    const classes = useStyles();
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const { meaningButtons, loading, getPaging } = useContext(PagingContext);

    const [loadingP, setLoadingP] = useState(false);
    const [deleteMeaningButton, setDeleteMeaningButton] = useState(null);
    const [addMeaningButtonOpen, setAddMeaningButtonOpen] = useState(false);
    const [editMeaningButtonOpen, setEditMeaningButtonOpen] = useState(false);
    const [infoButton, setInfoButton] = useState(null);
    const [orderedMeaningButtonIds, setOrderedMeaningButtonIds] = useState([]);
    const [orderModified, setOrderModified] = useState(false);
    const [saveOrderLoading, setSaveOrderLoading] = useState(false);

    const submindeleteMeaningButton = (item) => () => {
        setLoadingP(true);
        insyncHttps.paging
            .removeMeaningButtonFromOffice({
                officeId,
                meaningButtonId: item.meaningButtonId
            })
            .then(result => {
                setLoadingP(false);
                getPaging();
            })
            .catch(err => {
                setLoadingP(false);
                error.setMessage(err.message);
            });
    }

    const changeOrder = () => {
        setSaveOrderLoading(true);
        reorderMeaningButtons({
            officeId,
            meaningButtonIds: orderedMeaningButtonIds
        })
            .then(result => {
                setSaveOrderLoading(false);
                getPaging();
            })
            .catch(err => {
                setSaveOrderLoading(false);
                getPaging();
            });
    }

    function moveMeaningButtonUp(meaningButtonId) {
        const currentIndex = orderedMeaningButtonIds.indexOf(meaningButtonId);
        if (currentIndex > 0) {
            orderedMeaningButtonIds.splice(currentIndex, 1);
            orderedMeaningButtonIds.splice(
                currentIndex - 1,
                0,
                meaningButtonId
            );
            setOrderModified(true);
            setOrderedMeaningButtonIds([...orderedMeaningButtonIds]);
        }
    }

    function moveMeaningButtonDown(meaningButtonId) {
        const currentIndex = orderedMeaningButtonIds.indexOf(meaningButtonId);
        if (currentIndex < orderedMeaningButtonIds.length - 1) {
            orderedMeaningButtonIds.splice(currentIndex, 1);
            orderedMeaningButtonIds.splice(
                currentIndex + 1,
                0,
                meaningButtonId
            );
            setOrderedMeaningButtonIds([...orderedMeaningButtonIds]);
            setOrderModified(true);
        }
    }

    useEffect(() => {
        setOrderModified(false);
        setOrderedMeaningButtonIds(getSortedMeaningButtonIds(meaningButtons));
    }, [meaningButtons]);

    return (
        <div className={classes.infoContainer}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 16
                }}
            >
                <p className={classes.title}>  Meaning buttons </p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 10 }}>
                        {saveOrderLoading ?
                            <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                            :
                            orderModified && (
                                <div style={{ minWidth: 130 }}>
                                    <CustomButton
                                        width='full'
                                        label='save'
                                        onClick={changeOrder}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <IconButton
                        style={{ background: 'rgba(157, 162, 180, 0.08)', padding: 5, height: 40, width: 40 }}
                        onClick={() => {
                            setAddMeaningButtonOpen(true);
                        }}
                    >
                        <AddRoundedIcon
                            style={{ color: '#9DA2B4' }}
                            fontSize='medium'
                        />
                    </IconButton>
                </div>
                <Dialog
                    style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}
                    open={addMeaningButtonOpen}
                    disableAutoFocus={true}
                >
                    <AddMeaningButtons
                        handleClose={() => {
                            setAddMeaningButtonOpen(false);
                        }}
                    />
                </Dialog>
            </div>
            {loading ?
                <div
                    style={{
                        display: 'flex',
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                orderedMeaningButtonIds.map(meaningButtonId => {
                    const meaningButton = meaningButtons[meaningButtonId];

                    if (!meaningButtons[meaningButtonId]) {
                        return null;
                    }
                    return (
                        <div key={meaningButtonId}>
                            {deleteMeaningButton && deleteMeaningButton.meaningButtonId === meaningButtonId ?
                                <div
                                    className={classes.dataRoom}
                                    style={{ background: 'rgba(240, 100, 34, 0.12)' }}
                                >
                                    <div style={{ width: '60%', display: 'flex', alignItems: 'center' }}>
                                        <MeaningIcons
                                            iconId={meaningButton.iconId}
                                        />
                                        <p style={{ marginLeft: 10 }}>
                                            {meaningButton.name}
                                        </p>
                                    </div>
                                    {loadingP ?
                                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                                        :
                                        <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: 193 }}>
                                                <CustomButton
                                                    width='full'
                                                    label='Delete'
                                                    backgroundred='#F86F46'
                                                    onClick={submindeleteMeaningButton({
                                                        ...meaningButtons[meaningButtonId],
                                                        meaningButtonId
                                                    })}
                                                />
                                            </div>
                                            <span
                                                className={classes.subTitle}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setDeleteMeaningButton(null)
                                                }}
                                            >
                                                Cancel
                                            </span>
                                        </div>
                                    }
                                </div>
                                :
                                <div className={classes.dataRoom}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <MeaningIcons
                                            iconId={meaningButton.iconId}
                                        />
                                        <p style={{ marginLeft: 10 }}>
                                            {meaningButton.name}
                                        </p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <IconButton
                                            style={{ height: 40, width: 40 }}
                                            onClick={() => {
                                                moveMeaningButtonUp(meaningButtonId);
                                            }}
                                        >
                                            <IcArrowUp
                                                width='7'
                                                height='15'
                                                fill='#9DA2B4'
                                            />
                                        </IconButton>
                                        <IconButton
                                            style={{ height: 40, width: 40 }}
                                            onClick={() => {
                                                moveMeaningButtonDown(meaningButtonId);
                                            }}
                                        >
                                            <IcArrowDown
                                                width='7'
                                                height='15'
                                                fill='#9DA2B4'
                                            />
                                        </IconButton>
                                        <div style={{ width: 2, height: 33, backgroundColor: '#EDEEF0' }} />
                                        <IconButton
                                            style={{ height: 40, width: 40 }}
                                            onClick={() => {
                                                setEditMeaningButtonOpen(true);
                                                setInfoButton({ ...meaningButtons[meaningButtonId], meaningButtonId });
                                            }}
                                        >
                                            <CreateRounded style={{ color: '#9DA2B4' }} />
                                        </IconButton>
                                        <IconButton
                                            style={{ height: 40, width: 40 }}
                                            onClick={() => {
                                                setDeleteMeaningButton({ ...meaningButton, meaningButtonId });
                                                setLoadingP(false)
                                            }}

                                        >
                                            <DeleteRounded style={{ color: '#D8DAE1' }} />
                                        </IconButton>
                                    </div>
                                </div>
                            }
                            <Dialog
                                style={{ display: "flex" }}
                                className={classes.customDialog}
                                open={editMeaningButtonOpen}
                                disableAutoFocus={true}
                            >
                                <EditMeaningButton
                                    infoButton={infoButton}
                                    handleClose={() => {
                                        setEditMeaningButtonOpen(false);
                                    }}
                                />
                            </Dialog>
                        </div>
                    )
                })
            }
            <Divider style={{ backgroundColor: '#EDEEF0' }} />
        </div>
    );
}

export default MeaningButtons;

function getSortedMeaningButtonIds(meaningButtons) {
    return Object.keys(meaningButtons)
        .filter(meaningButtonId => {
            return Object.keys(meaningButtons).includes(meaningButtonId);
        })
        .sort((a, b) => {
            if (meaningButtons[a].name > meaningButtons[b].name) {
                return 1;
            }
            if (meaningButtons[a].name < meaningButtons[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const meaningButtonA = { ...meaningButtons[a] };
            const meaningButtonB = { ...meaningButtons[b] };
            meaningButtonA.listPosition = meaningButtonA.listPosition
                ? meaningButtonA.listPosition
                : 0;
            meaningButtonB.listPosition = meaningButtonB.listPosition
                ? meaningButtonB.listPosition
                : 0;

            return meaningButtonA.listPosition - meaningButtonB.listPosition;
        });
}