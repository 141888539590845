import React, { useState, useContext } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import DesktopMacRoundedIcon from '@material-ui/icons/DesktopMacRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

import { BillingContext } from "../BillingContext";
import { appContext } from "../../../../../contexts/app";
import { OfficeContext } from "../../OfficeContext";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";
import CustomTextField from "../../../../CustomTextField";
import CustomButton from "../../../../CustomButton";

const useStyles = makeStyles({
   infoContainer: {
      borderRadius: 4,
      paddingTop: 34,
      paddingBottom: 34,
      paddingLeft: 31,
      paddingRight: 26,
      background: 'white',
      border: '1px solid #EDEEF0',
      marginTop: 30,
      userSelect: 'none',
      fontFamily: 'SF Pro Display'
   },
   title: {
      fontSize: 24,
      fontWeight: 'bold',
      margin: 0,
      wordBreak: 'break-all'
   },
   subTitle: {
      fontSize: 16,
      fontWeight: 'normal',
      margin: 0,
      color: '#979797',
      marginLeft: 10
   },
   numberLicenses: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 25,
      width: 25,
      background: '#F7F8F9',
      color: '#197FE8',
      borderRadius: 15,
   },
   inUseLisc: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 15,
      paddingBottom: 15,
   },
   containerEditDelete: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 10,
      justifyContent: 'space-between'
   },
});

export default function (props) {
   const classes = useStyles();
   const licenses = props.licenses;

   const activeLicenses = Object.keys(licenses).filter(e => licenses[e].active === true)

   return (
      <div className={classes.infoContainer}>
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p className={classes.title} style={{ marginBottom: 10 }}>
               Licenses
            </p>
            <div style={{ display: 'flex', marginBottom: 10 }}>
               <DesktopMacRoundedIcon style={{ color: '#197FE8' }} />
               <div className={classes.numberLicenses}>
                  {activeLicenses.length}
               </div>
               <span style={{ marginLeft: 10 }}>Available Licenses </span>
            </div>
         </div>
         <LicenseList licenses={licenses} />
      </div>
   );
}

function LicenseList(props) {
   const classes = useStyles();
   const { officeId } = useContext(OfficeContext);
   const { subscriptions } = useContext(BillingContext);
   const { error } = useContext(appContext);
   const licenses = props.licenses;

   const [editLicense, setEditLicense] = useState(null);
   const [deleteLicense, setDeleteLicense] = useState(null);
   const [name, setName] = useState(null);
   const [errorField, setErrorField] = useState(false);

   const subminNewName = (item) => () => {
      const licenseId = item.licenseId
      if (name === '' || name === undefined) {
         setErrorField(true)
      } else {
         insyncHttps.billing
            .renameLicense({ officeId, licenseId, name })
            .then(result => {
               setEditLicense(null);
            })
            .catch(err => {
               error.setMessage(err.message);
            });
      }
   }

   const submindeleteLicense = (item) => () => {
      const licenseId = item.licenseId
      insyncHttps.billing
         .removeLicenseFromOffice({
            officeId,
            licenseId
         })
         .then(result => {
            subscriptions.getSubs();
         })
         .catch(error => {
            console.error(error);
         });
   }

   return (
      <div>
         {Object.keys(licenses)
            .sort((a, b) => {
               const nameA = licenses[a].friendlyName;
               const nameB = licenses[b].friendlyName;

               if (nameA > nameB) {
                  return 1;
               }
               if (nameA < nameB) {
                  return -1;
               }
               return 0;
            })
            .map(licenseId => {

               return (
                  <div
                     key={licenseId}
                     style={{ borderTop: '1px solid #EDEEF0', fontFamily: 'SF Pro Display', }}
                  >
                     <div style={{ height: 75 }}>
                        {deleteLicense === licenses[licenseId] || editLicense === licenses[licenseId] ?

                           <div
                              className={classes.containerEditDelete}
                              style={{ background: deleteLicense ? 'rgba(240, 100, 34, 0.12)' : 'white' }}
                           >
                              <div style={{ width: deleteLicense ? '60%' : '80%' }}>
                                 {deleteLicense ?
                                    <div>
                                       <p style={{ margin: 0, color: "#EC5829" }}>
                                          {licenses[licenseId].friendlyName}
                                       </p>
                                       <span> Inactive </span>
                                    </div>
                                    :
                                    <CustomTextField
                                       value={name}
                                       placeholder='Workstation name'
                                       onChange={e => {
                                          setName(e.target.value);
                                       }}
                                       error={name === '' ? errorField : false}
                                    />
                                 }
                              </div>
                              <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                 <div style={{ width: deleteLicense ? 193 : 127 }}>
                                    <CustomButton
                                       width='full'
                                       label={deleteLicense ? 'Delete licensce' : 'Save Name'}
                                       backgroundred={deleteLicense ? '#F86F46' : ''}
                                       onClick={
                                          deleteLicense ?
                                             submindeleteLicense({ ...licenses[licenseId], licenseId })
                                             :
                                             subminNewName({ ...licenses[licenseId], licenseId })
                                       }
                                    />
                                 </div>
                                 <span
                                    className={classes.subTitle}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                       setEditLicense(null)
                                       setDeleteLicense(null)
                                    }}
                                 >
                                    Cancel
                                 </span>
                              </div>
                           </div>
                           :
                           <div className={classes.inUseLisc}>
                              <div>
                                 <p style={{ margin: 0 }}>
                                    {licenses[licenseId].friendlyName}
                                 </p>
                                 {licenses[licenseId].active ? (
                                    <span style={{ color: "#9DCD2A" }}> Active </span>
                                 ) : (
                                    <span style={{ color: "#EC5829" }}> Inactive </span>
                                 )}
                              </div>
                              <div>
                                 <IconButton
                                    style={{ height: 40, width: 40 }}
                                    onClick={() => {
                                       setEditLicense(licenses[licenseId]);
                                       setName(licenses[licenseId].friendlyName);
                                       setDeleteLicense(null);
                                    }}
                                 >
                                    <EditRoundedIcon style={{ color: '#9DA2B4' }} />
                                 </IconButton>
                                 {licenses[licenseId].active ? null : (
                                    <IconButton
                                       style={{ height: 40, width: 40 }}
                                       onClick={() => {
                                          setDeleteLicense(licenses[licenseId]);
                                          setEditLicense(null);
                                       }}
                                    >
                                       <DeleteRoundedIcon style={{ color: '#9DA2B4' }} />
                                    </IconButton>
                                 )}
                              </div>
                           </div>
                        }

                     </div>
                  </div>
               )
            })}
      </div>
   );
}