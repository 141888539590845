import { createContext, useState } from "react";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export const PagingContext = createContext({
    persons: {},
    rooms: {},
    meaningButtons: {},
    loading: false,
    getPaging: () => {},
    error: null
});

export const usePaging = officeId => {
    const [persons, setPersons] = useState({});
    const [rooms, setRooms] = useState({});
    const [loading, setLoading] = useState(false);
    const [meaningButtons, setMeaningButtons] = useState({});
    const [error, setError] = useState(null);

    const getPaging = () => {
        setError(null);
        setLoading(true);
        insyncHttps.paging
            .getPaging({ officeId })
            .then(result => {
                setLoading(false);
                setPersons(result.data.persons);
                setRooms(result.data.rooms);
                setMeaningButtons(result.data.meaningButtons || {});
            })
            .catch(error => {
                setError(error.message);
                console.error(error);
                setLoading(false);
                setPersons({});
                setRooms({});
                setMeaningButtons({});
            });
    };

    return { persons, rooms, meaningButtons, loading, getPaging, error };
};
