import React from 'react';

const ArrowDown = (props) => {
    const { width, height, fill } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.50002 0C4.77616 0 5.00002 0.223927 5.00002 0.500154V11.0049H7.00002C7.20006 11.0077 7.37917 11.1296 7.45534 11.3146C7.53151 11.4996 
            7.49008 11.7123 7.35002 11.8552L4.35002 14.8561C4.15221 15.048 3.83783 15.048 3.64002 14.8561L0.640021 11.8552C0.498717 11.711 0.457952 11.496 
            0.536675 11.31C0.615398 11.1241 0.798166 11.0037 1.00002 11.0049H3.00002V0.500154C3.00002 0.223927 3.22388 0 3.50002 0H4.50002Z" fill={fill}
            />


        </svg>

    )
}
export default ArrowDown;