import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import CustomNavBar from '../../CustomNavBar';

const useStyles = makeStyles({
    typography: {
        color: '#979797',
        fontSize: 16,
        marginTop: 15
    },
});


function TermsOfService() {
    const classes = useStyles();

    return (
        <div>
            <CustomNavBar/>
            <Grid
                container
                style={{ marginTop: 30 }}
                justifyContent='center'
            >
                <Grid
                    item
                    xs={11} sm={7} md={5} lg={4}
                    style={{ marginTop: 30, fontFamily: 'SF Pro Display' }}
                >
                    <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 20 }}>
                        Terms of Service
                    </p>
                    <p className={classes.typography}>
                        Enter the email address you used when you joined and we’ll
                        send you instructions to reset your password.<br /><br />

                        For security reasons, we do NOT store your password.
                        So rest assured that we will never send your password via email.
                    </p>
                </Grid>
            </Grid>
        </div>
    );
}

export default TermsOfService;