import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import { mainRouterHistory } from "../../../routerHistories";
import { OfficeContext, useOffice } from "./OfficeContext";

import Billing from './Billing'
import Roles from './Roles';
import Paging from './Paging';
import PatientTracking from './PatientTracking';
import Report from './Report';
import Messaging from './Messaging';
import CustomNavBar from '../../CustomNavBar';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function OfficeDetail(props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        mainRouterHistory.push(`/office/${props.match.params.id}`, {
            officeId: props.match.params.id,
        });
    }, []);

    return (
        <OfficeContext.Provider
            value={useOffice(
                props.match.params.id,
            )}
        >
            <CustomNavBar
                tabs={true}
                handleChange={handleChange}
                value={value}
            />
            <Grid
                container
                style={{ background: '#F8F9FB', paddingBottom: 30 }}
                justifyContent='center'
            >
                <Grid
                    item
                    xs={11} lg={8} xl={7}
                    style={{ marginTop: 5 }}
                >
                    <TabPanel value={value} index={0}>
                        <Billing />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Roles />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Paging />
                    </TabPanel>
                </Grid>

                <Grid
                    item
                    xs={11} lg={10} xl={9}
                    style={{ marginTop: 5 }}
                >
                    <TabPanel value={value} index={3}>
                        <Messaging />
                    </TabPanel>
                </Grid>

                <Grid
                    item
                    xs={11} lg={8} xl={7}
                    style={{ marginTop: 5 }}
                >
                    <TabPanel value={value} index={4}>
                        <PatientTracking />
                    </TabPanel>
                </Grid>
                
                <Grid
                    item
                    xs={11} lg={7} 
                    style={{ marginTop: 5 }}
                >
                    <TabPanel value={value} index={5}>
                        <Report />
                    </TabPanel>
                </Grid>

               
            </Grid>
        </OfficeContext.Provider>
    );
}

export default OfficeDetail;