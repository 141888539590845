import React, { useState, useContext } from "react";
import {
    Card,
    CardContent,
    CardActions,
    CircularProgress,
    Modal,
    makeStyles,
    Divider
} from "@material-ui/core";
import { appContext } from "../../../../contexts/app";
import { OfficeContext } from "../OfficeContext";
import { UsersContext } from "./UsersContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

import CustomTextField from "../../../CustomTextField";
import CustomCheckBox from "../../../CustomCheckBox";
import CustomButton from "../../../CustomButton";

const useStyles = makeStyles({
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797'
    },
    textField: {
        fontSize: 16,
        marginBottom: 10,
        marginTop: 20,
        textAlign: 'left',
    },
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        fontFamily: 'SF Pro Display',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    }
});

export default React.forwardRef((props, ref) => {
    const classes = useStyles();
    const handleClose = props.handleClose;

    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);

    const users = useContext(UsersContext);

    const [loading, setLoading] = useState(false);
    const [createUserOpen, setCreateUserOpen] = useState(false);
    const [errorField, setErrorField] = useState(false);
    const [emailValidate, setEmailValidate] = useState(false);

    const [form, setForm] = useState({
        name: '',
        email: '',
        selectedRole: '',
        billingAdmin: false,
        officeAdmin: false,
        officeUser: false,
    });

    const handleChange = (event) => {
        if (event.target.name === 'billingAdmin') {
            setForm({
                ...form,
                billingAdmin: event.target.checked,
                selectedRole: event.target.name,
                officeAdmin: false,
                officeUser: false
            });
        } else if (event.target.name === 'officeAdmin') {
            setForm({
                ...form,
                officeAdmin: event.target.checked,
                selectedRole: event.target.name,
                billingAdmin: false,
                officeUser: false
            });
        } else {
            setForm({
                ...form,
                officeUser: event.target.checked,
                selectedRole: event.target.name,
                billingAdmin: false,
                officeAdmin: false
            });
        }
    };

    const onChangeEmail = (event) => {
        const caracteres = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const t = event.currentTarget.value.split(" ");
        let text = "";
        t.map((r) => {
            if (r !== "") {
                text += r;
            }
        });
        setForm({ ...form, email: text.toLowerCase() });
        if (text !== "") {
            if (caracteres.test(text)) {
                setEmailValidate(false);
            } else {
                setEmailValidate(true);
            }
        } else {
            setEmailValidate(true);
        }
    };

    const addUserSequence = async () => {
        setLoading(true);
        if (form.email === '' ||
            (form.officeAdmin === false && form.officeUser === false && form.billingAdmin === false)
        ) {
            setErrorField(true)
            setLoading(false);
        } else {
            const userExists = await new Promise((resolve, reject) => {
                insyncHttps.insyncUser
                    .doesUserExist({ email: form.email })
                    .then(result => {
                        setLoading(false);
                        resolve(result.data);
                    })
                    .catch(err => {
                        error.setMessage(err.message);
                        setLoading(false);
                        reject(err);
                    });
            });

            if (userExists) {
                setLoading(true);
                insyncHttps.insyncUser
                    .addOfficeRoleToUser({
                        fullName: form.name,
                        officeId,
                        email: form.email,
                        roleName: form.selectedRole
                    })
                    .then(result => {
                        setLoading(false);
                        users.getUsers();
                        handleClose();
                    })
                    .catch(err => {
                        setLoading(false);
                        error.setMessage(err.message);
                    });
            } else {
                setCreateUserOpen(true);
            }
        }
    };

    return (
        <Card className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 23 }} className={classes.title}>
                        Create user
                    </p>
                    <p
                        style={{ marginBottom: 23, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }}/>
                <div style={{ marginBottom: 20, paddingLeft: 25, paddingRight: 25 }}>
                    {/* <p className={classes.textField}>
                        Full name
                    </p>
                    <CustomTextField
                        value={form.name}
                        placeholder="Member Name"
                        error={form.name === '' ? errorField : false}
                        onChange={e => {
                            setForm({ ...form, name: e.target.value });
                        }}
                    /> */}
                    <p className={classes.textField} style={{ marginTop: 31 }}>
                        Email
                    </p>
                    <CustomTextField
                        value={form.email}
                        placeholder="member@mail.com"
                        error={form.email !== '' ? emailValidate : errorField}
                        onChange={onChangeEmail}
                        helperText={form.email && emailValidate ? "Please enter a valid email" : ''}
                    />
                    <div style={{ marginTop: 25 }}>
                        <p>Roles</p>
                        <CustomCheckBox
                            onClick={handleChange}
                            checked={form.officeUser}
                            name='officeUser'
                        />
                        <span
                            style={{ color: form.officeUser === true ? '#2F80ED' : '#979797' }}
                        >
                            Office user
                        </span>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <CustomCheckBox
                            onClick={handleChange}
                            checked={form.officeAdmin}
                            name='officeAdmin'
                        />
                        <span
                            style={{ color: form.officeAdmin === true ? '#2F80ED' : '#979797' }}
                        >
                            Office admin
                        </span>
                    </div>
                    <div style={{ marginBottom: 30, marginTop: 15 }}>
                        <CustomCheckBox
                            onClick={handleChange}
                            checked={form.billingAdmin}
                            name='billingAdmin'
                        />
                        <span
                            style={{ color: form.billingAdmin === true ? '#2F80ED' : '#979797' }}
                        >
                            Billing admin
                        </span>
                    </div>
                </div>
            </CardContent>
            <Divider style={{ marginBottom: 32 }} />
            <CardActions style={{ paddingLeft: 25, paddingRight: 25 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 15 }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        label='Create user'
                        width='full'
                        onClick={addUserSequence}
                    />
                }
            </CardActions>
            <Modal
                style={{ display: "flex" }}
                open={createUserOpen}
                onClose={() => {
                    setCreateUserOpen(false);
                }}
            >
                <CreateUser
                    email={form.email}
                    handleClose={() => {
                        setCreateUserOpen(false);
                    }}
                />
            </Modal>
        </Card>
    );
});

const CreateUser = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const [loading, setLoading] = useState(false);
    
    const email = props.email;
    const handleClose = props.handleClose;

    return (
        <Card ref={ref} className={classes.cardContainer}>
            <CardContent>
                <p style={{ marginBottom: 20 }} className={classes.title}>
                    Create user?
                </p>
                <Divider style={{ marginBottom: 20 }} />
                <div style={{ marginBottom: 20, paddingLeft: 15, paddingRight: 15 }}>
                    A user does not exist for email address {email}. Would you like
                    to create the user?
                </div>
                <Divider style={{ marginBottom: 15 }} />
            </CardContent>
            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 10 }}>
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                <CardActions>
                    <div style={{ width: '50%' }}>
                        <CustomButton
                            label='Create'
                            width='full'
                            onClick={() => {
                                setLoading(true);
                                insyncHttps.insyncUser
                                    .createUser({ officeId, email })
                                    .then(result => {
                                        setLoading(false);
                                        alert(`user created. password: ${result.data}`);
                                        handleClose();
                                    })
                                    .catch(err => {
                                        setLoading(false);
                                        error.setMessage(err.message);
                                    });
                            }}
                        />
                    </div>
                    <div style={{ width: '50%' }}>
                        <CustomButton
                            label='Cancel'
                            width='full'
                            backgroundred='#F86F46'
                            onClick={() => {
                                handleClose();
                            }}
                        />
                    </div>

                </CardActions>
            }
        </Card>
    );
});
