import React from 'react';
import moment from "moment";
import ReactToPrint from "react-to-print";
import { makeStyles } from '@material-ui/core';

import useVisitReport from './useVisitReport';
import VisitReportContext from "./VisitReportContext";
import Controls from './Controls';
import ReportFirstConainer from './ReportFirstConainer';
import ReportSecundContainer from './ReportSecundContainer';
import ReportThirdContainer from './ReportThirdContainer';
import ReportFourthContainer from './ReportFourthContainer';

import CustomButton from '../../../CustomButton';

const useStyles = makeStyles({
    infoContainer: {
        borderRadius: 4,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 26,
        background: 'white',
        border: '1px solid #EDEEF0',
        marginTop: 20,
        fontFamily: 'SF Pro Display'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
});

function Report() {
    const classes = useStyles();
    const reportCtx = useVisitReport();
    const reportTime = moment(reportCtx.reportTime).format("LT YYYY/MM/DD ");
    const endDate = moment(reportCtx.reportEndDate).format("YYYY/MM/DD");
    const startDate = moment(reportCtx.reportStartDate).format("YYYY/MM/DD");
    const { printRef } = reportCtx;

    return (
        <VisitReportContext.Provider value={reportCtx}>
            <div className={classes.infoContainer}>
                <p className={classes.title}>
                    Date Range
                </p>
                <Controls />
            </div>
            {reportCtx.reportTime > 0 && (
                <div>
                    <div style={{
                        width: '100%',
                        marginBottom: 15,
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 15
                    }}>
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <CustomButton label='print' />
                                );
                            }}
                            content={() => {
                                return printRef.current;
                            }}
                        />
                        <div style={{ marginLeft: 10 }}>
                            <div>{`Generated at ${reportTime}`}</div>
                            <div>{`${startDate} - ${endDate}`}</div>
                        </div>
                    </div>
                    <div ref={printRef}>
                        <ReportFirstConainer />
                        <ReportSecundContainer />
                        <ReportThirdContainer />
                    </div>
                    <ReportFourthContainer />
                </div>
            )}

        </VisitReportContext.Provider>
    );
}

export default Report;
